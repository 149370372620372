import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableMultipleClick]'
})
export class DisableMultipleClickDirective {

  constructor(private readonly elementRef: ElementRef) { }

  @HostListener('click', ['$event'])
  click() {
    this.elementRef.nativeElement.setAttribute('disabled', true);
    setTimeout(() => {
      this.elementRef.nativeElement.removeAttribute('disabled');
    }, 1000);
  }
}
