import { Directive, ElementRef, forwardRef, HostListener, Inject, Input, Renderer2 } from '@angular/core';
import { WidgetGridComponent } from '../components/grid/grid.component';
import { Rectangle } from '../models/Rectangle.model';
import { WidgetComponent } from '../components/widget/widget.component';
import { PathIterator } from '../models/PathIterator.model';
import {
  generateWidgetOnGrid,
  getWidgetHighlightColor,
  hasPlacementBlocking, removeItemFromWidgets, uprightsCanGoTroughDevice
} from '../../prisma-xs/helpers/widget.helper';
import { IArticle } from '../../prisma-xs/models/article.model';

export interface RectanglePixels {
  top: number;
  left: number;
  height: number;
  width: number;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ngxWidgetMover]'
})
export class WidgetMoverDirective {
  public cellHeight: number;
  public cellWidth: number;
  public startRender: RectanglePixels;
  public gridPositions: Rectangle;
  public moverOffset: Rectangle;
  public desiredPosition: any;
  public startPosition: Rectangle;
  public enableDrag: string = null;
  private _onMoveListener = this.onMove.bind(this);
  private _onUpListener = this.onUp.bind(this);

  @Input()
  public ngxWidgetMover = false;

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              @Inject(forwardRef(() => WidgetGridComponent))
              private gridCmp: WidgetGridComponent,
              @Inject(forwardRef(() => WidgetComponent))
              private widgetCmp: WidgetComponent) {
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('pointerdown', ['$event'])
  onDown(event) {
    event.preventDefault();
    if (event.button !== 0) {
      // If it's not left click
      return;
    }
    this.renderer.addClass(this.widgetCmp.getEl().nativeElement, 'wg-moving');
    const widgetContainer = this.widgetCmp.getEl().nativeElement;

    this.startPosition = this.gridCmp.getWidgetPosition(this.widgetCmp);

    this.startRender = {
      top: widgetContainer.offsetTop,
      left: widgetContainer.offsetLeft,
      height: widgetContainer.clientHeight,
      width: widgetContainer.clientWidth
    }; // pixel values

    const eventOffsetX = event.offsetX || event.layerX;
    const eventOffsetY = event.offsetY || event.layerY;

    this.desiredPosition = { top: this.startRender.top, left: this.startRender.left };

    this.moverOffset = new Rectangle({
      top: eventOffsetY + this.el.nativeElement.offsetTop || 0,
      left: eventOffsetX + this.el.nativeElement.offsetLeft || 0
    });

    this.gridPositions = this.gridCmp.getGridRectangle();
    this.cellHeight = (this.gridCmp.grid.cellSize.height / 100) * this.gridPositions.height;
    this.cellWidth = (this.gridCmp.grid.cellSize.width / 100) * this.gridPositions.width;
    this.enableDrag = this.widgetCmp.getConfig().id;

    this.renderer.setStyle(this.widgetCmp.getEl().nativeElement, 'z-index', this.ngxWidgetMover ? 0 : 100);

    if (typeof PointerEvent !== 'undefined') {
      window.addEventListener('pointermove', this._onMoveListener);
      window.addEventListener('pointerup', this._onUpListener);
    } else {
      window.addEventListener('mousemove', this._onMoveListener);
      window.addEventListener('mouseup', this._onUpListener);
    }
  }

  onMove(event: MouseEvent) {
    if (this.enableDrag === this.widgetCmp.getConfig().id) {
      event.preventDefault();
      const eventClientX = event.pageX;
      const eventClientY = event.pageY;
      const startRender = this.startRender;
      const gridDimensions = this.gridPositions;
      const desiredPosition = this.desiredPosition;
      // normalize the drag position
      const dragPositionX = Math.round(eventClientX) - gridDimensions.left;
      const dragPositionY = Math.round(eventClientY) - gridDimensions.top;

      desiredPosition.top = Math.min(
        Math.max(dragPositionY - this.moverOffset.top, 0),
        gridDimensions.height - startRender.height
      );
      desiredPosition.left = Math.min(
        Math.max(dragPositionX - this.moverOffset.left, 0),
        gridDimensions.width - startRender.width
      );
      const currentFinalPos: Rectangle = this.determineFinalPos(this.startPosition,
        desiredPosition,
        this.startRender,
        this.cellHeight,
        this.cellWidth);
      const uprightsCanGoTroughMovingKit = uprightsCanGoTroughDevice(this.gridCmp.placedKits, this.widgetCmp.widgetUUID);
      const availablePlacingGrabbingArticleOnGrid = !hasPlacementBlocking(
        generateWidgetOnGrid(this.widgetCmp.article, uprightsCanGoTroughMovingKit),
        currentFinalPos, removeItemFromWidgets(this.widgetCmp.position, this.gridCmp.placedKits), this.gridCmp.rows);

      const highlightColor = this.getHighlightColor(currentFinalPos, availablePlacingGrabbingArticleOnGrid);
      this.gridCmp.highlightArea(currentFinalPos, highlightColor);
      this.setCursorStyle(availablePlacingGrabbingArticleOnGrid);

      this.renderer.setStyle(this.widgetCmp.getEl().nativeElement, 'top', desiredPosition.top + 'px');
      this.renderer.setStyle(this.widgetCmp.getEl().nativeElement, 'left', desiredPosition.left + 'px');
    }
  }

  private getHighlightColor(_: Rectangle, availablePlacingGrabbingArticleOnGrid: boolean): string {
    return getWidgetHighlightColor(availablePlacingGrabbingArticleOnGrid);
  }

  private setCursorStyle(availablePlacingGrabbingArticleOnGrid) {
    this.renderer.setStyle(this.widgetCmp.getEl().nativeElement, 'cursor',
      availablePlacingGrabbingArticleOnGrid ? 'move' : 'no-drop');
  }

  onUp(event: MouseEvent) {
    if (this.enableDrag === this.widgetCmp.getConfig().id) {
      event.preventDefault();
      const eventClientX = event.pageX;
      const eventClientY = event.pageY;
      const startRender = this.startRender;
      const gridDimensions = this.gridPositions;
      const desiredPosition = this.desiredPosition;
      // normalize the drag position
      const dragPositionX = Math.round(eventClientX) - gridDimensions.left;
      const dragPositionY = Math.round(eventClientY) - gridDimensions.top;

      desiredPosition.top = Math.min(
        Math.max(dragPositionY - this.moverOffset.top, 0),
        gridDimensions.height - startRender.height
      );
      desiredPosition.left = Math.min(
        Math.max(dragPositionX - this.moverOffset.left, 0),
        gridDimensions.width - startRender.width
      );
      const anchorTop = this.getAnchor(Math.max(dragPositionY, 0), this.cellHeight, 1);
      const anchorLeft = this.getAnchor(Math.max(dragPositionX, 0), this.cellWidth, 1);
      const dropPosition: any = this.gridCmp.rasterizeCoords(anchorLeft, anchorTop);
      const obstructingWidgetId = this.gridCmp.areaObstructor(dropPosition);
      let finalPos;
      if (obstructingWidgetId && this.ngxWidgetMover) {
        const obstructingWidgetCmp: WidgetComponent = this.gridCmp.getWidgetById(obstructingWidgetId);
        const obstructingWidgetPosition = this.gridCmp.getWidgetPositionByWidgetId(obstructingWidgetId);
        const draggedWidgetPosition = this.widgetCmp.position;
        this.widgetCmp.position = obstructingWidgetPosition;
        this.gridCmp.updateWidget(this.widgetCmp, true);
        obstructingWidgetCmp.position = draggedWidgetPosition;
        this.gridCmp.updateWidget(obstructingWidgetCmp, true);
      } else {
        finalPos = this.determineFinalPos(this.startPosition,
          desiredPosition,
          this.startRender,
          this.cellHeight,
          this.cellWidth);
        this.widgetCmp.position = this.checkForBlockingPositions(finalPos);
        this.gridCmp.updateWidget(this.widgetCmp, false);
      }
      this.gridCmp.resetHighlights();
      this.renderer.removeClass(this.widgetCmp.getEl().nativeElement, 'wg-moving');
      this.renderer.removeStyle(this.widgetCmp.getEl().nativeElement, 'z-index');
      this.enableDrag = null;
    }
    if (typeof PointerEvent !== 'undefined') {
      window.removeEventListener('pointermove', this._onMoveListener);
      window.removeEventListener('pointerup', this._onUpListener);
    } else {
      window.removeEventListener('mousemove', this._onMoveListener);
      window.removeEventListener('mouseup', this._onUpListener);
    }
    this.renderer.setStyle(this.widgetCmp.getEl().nativeElement, 'cursor', 'default');
  }

  private checkForBlockingPositions(finalPos: Rectangle) {
    return this.getWidgetBlockingPosition(finalPos, this.widgetCmp.position, this.gridCmp.placedKits, this.gridCmp.rows) || finalPos;
  }

  private getWidgetBlockingPosition(finalPos: Rectangle, position: Rectangle, placedKits: IArticle[], enclosureHeight: number): Rectangle {
    const uprightsCanGoTroughMovingKit = uprightsCanGoTroughDevice(this.gridCmp.placedKits, this.widgetCmp.widgetUUID);
    if (hasPlacementBlocking(
      generateWidgetOnGrid(this.widgetCmp.article, uprightsCanGoTroughMovingKit), finalPos,
      removeItemFromWidgets(position, placedKits), enclosureHeight)) {
      return {
        ...this.startPosition
      } as Rectangle;
    } else {
      return null;
    }
  }

  getAnchor(val: number, cellWOrH: number, marginFactor = 2): number {
    return (val % cellWOrH) > (cellWOrH / marginFactor) ? val + Math.floor(cellWOrH) : val;
  }

  determineFinalPos(startPos: Rectangle, desiredPos: Rectangle, startRender: RectanglePixels,
                    cellHt: number, cellWd: number): Rectangle {
    if (startRender.top === Math.round(desiredPos.top) && startRender.left === Math.round(desiredPos.left)) {
      return startPos;
    }

    const anchorTop = this.getAnchor(desiredPos.top, cellHt);
    const anchorLeft = this.getAnchor(desiredPos.left, cellWd);
    const movedDown = anchorTop >= startRender.top;
    const movedRight = anchorLeft >= startRender.left;
    const desiredFinalPosition: any = this.gridCmp.rasterizeCoords(anchorLeft, anchorTop);
    const path = new PathIterator(desiredFinalPosition, startPos);
    while (path && path.hasNext()) {
      const currPos = path.next();

      const targetArea = new Rectangle({
        top: currPos.top,
        left: currPos.left,
        height: startPos.height,
        width: startPos.width
      });

      const options = {
        excludedArea: startPos,
        fromBottom: movedDown,
        fromRight: movedRight
      };
      // If widget swap is enabled and area is obstructed, show original position
      if (this.ngxWidgetMover && this.gridCmp.isAreaObstructed(targetArea, options)) {
        return new Rectangle(startPos);
      }
      if (!this.gridCmp.isAreaObstructed(targetArea, options)) {
        // try to get closer to the desired position by leaving the original path
        const height = targetArea.height;
        const width = targetArea.width;
        if (desiredFinalPosition.top < targetArea.top) {
          while (desiredFinalPosition.top <= (targetArea.top - 1)) {
            const checkRect = new Rectangle({ top: targetArea.top - 1, left: targetArea.left, height, width });
            const isRectVacant = !this.gridCmp.isAreaObstructed(checkRect, options);
            if (isRectVacant) {
              targetArea.top--;
            } else {
              break;
            }
          }
        } else if (desiredFinalPosition.top > targetArea.top) {
          while (desiredFinalPosition.top >= (targetArea.top + 1)) {
            const checkRect = new Rectangle({ top: targetArea.top + 1, left: targetArea.left, height, width });
            const isRectVacant = !this.gridCmp.isAreaObstructed(checkRect, options);
            if (isRectVacant) {
              targetArea.top++;
            } else {
              break;
            }
          }
        }
        if (desiredFinalPosition.left < targetArea.left) {
          while (desiredFinalPosition.left <= (targetArea.left - 1)) {
            const checkRect = new Rectangle({ top: targetArea.top, left: targetArea.left - 1, height, width });
            const isRectVacant = !this.gridCmp.isAreaObstructed(checkRect, options);
            if (isRectVacant) {
              targetArea.left--;
            } else {
              break;
            }
          }
        } else if (desiredFinalPosition.left > targetArea.left) {
          while (desiredFinalPosition.left >= (targetArea.left + 1)) {
            const checkRect = new Rectangle({ top: targetArea.top, left: targetArea.left + 1, height, width });
            const isRectVacant = !this.gridCmp.isAreaObstructed(checkRect, options);
            if (isRectVacant) {
              targetArea.left++;
            } else {
              break;
            }
          }
        }
        return new Rectangle(targetArea);
      }
    }
    return new Rectangle(startPos);
  }
}
