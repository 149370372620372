<div #guestInformationModal class="modal guest-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content rounded-0 guest-modal-width">
      <div class="d-flex flex-row modal-body guest-modal-border border-0 p-0" fxLayout.xs="column">
        <div class="guest-bg flex-width-127"></div>
        <div class="d-flex flex-column p-4 flex-width-127">
          <div class="d-flex align-items-center flex-auto">
            <div>
              <div class="pb-3"><img alt="" src="/assets/images/projekt_konfigurieren_cloud_icon.svg"></div>
              <div class="mb-2 font-size-xxl font-weight-bolder" i18n="@@app.configure_your_project">Configure your project</div>
              <div class="mb-4 font-size-m font-weight-semibold" i18n="@@app.store_your_project">Store your projects and quotes in the Cloud. Access your projects from everywhere. (internet and tablet)</div>
            </div>
          </div>
          <div class="flex-height-30">
            <button appDisableMultipleClick
                    class="btn btn-lg bg-light-blue text-color-white pr-3 pl-3 border-rounded d-flex align-items-center"
                    (click)="onLogin()">
              <img alt="" src="/assets/images/new_mid_icon.svg">
              <span class="text-uppercase ml-2" i18n="@@app.get_started">Get started</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
