import { Injectable } from '@angular/core';
import { interval, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateAppService {

  private updateAvailable$ = new Subject<boolean>();
  private updateInterval = 2 * 60 * 1000;

  constructor(
              private readonly configService: ConfigurationService,
              private readonly dataService: DataService) {
    this.checkForUpdate();

    // updates.versionUpdates.subscribe(_ => {
    //   console.log('new version');
    //   console.log(_);
    //   updates.activateUpdate().then(() => this.updateAvailable$.next(true));
    // });
  }

  private checkForUpdate() {
    if (!environment.production) {
      return;
    }

    const everyFiveMinutes$ = interval(this.updateInterval);
    // everyFiveMinutes$.subscribe(() => this.updates.checkForUpdate());
  }

  applicationUpdateListener() {
    return this.updateAvailable$.asObservable();
  }

  getUpdateInfo(language: string) {
    return this.dataService.get(environment.apiEndpoint + '/api/v1/application/version_info?l=' + language);
  }
}
