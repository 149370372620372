<app-grid-overlay [showGrid]="showGrid"
                  [renderer]="gridRenderer"
                  [highlight]="highlightedArea"
                  [highlightColor]="highlightColor"
                  [cols]="columns"
                  [rows]="rows">
</app-grid-overlay>
<div class="wg-grid-widgets">
  <ng-content></ng-content>
</div>
