import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

declare var jQuery: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-guest-information',
  templateUrl: './guest-information.component.html',
  styleUrls: ['./guest-information.component.scss']
})
export class GuestInformationComponent {

  @ViewChild('guestInformationModal') projectCreateModalEl: ElementRef;

  @Output() login = new EventEmitter();

  onLogin() {
    this.login.emit();
  }

  openModal() {
    jQuery(this.projectCreateModalEl.nativeElement).modal({backdrop: 'static', keyboard: false});
  }

  closeModal() {
    jQuery(this.projectCreateModalEl.nativeElement).modal('hide');
  }
}
