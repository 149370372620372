import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  generatePhoneNumber(phoneNumber: string, validNumber: boolean) {
    phoneNumber = phoneNumber.replace(/ /g, '');
    if (phoneNumber && validNumber) {
      if (phoneNumber.startsWith('00')) {
        phoneNumber = '+' + phoneNumber.slice(2, phoneNumber.length);
      }
      if (phoneNumber.startsWith('+') && phoneNumber.slice(3, 4) === '0') {
        phoneNumber = phoneNumber.substring(0, 3) + phoneNumber.substring(4, phoneNumber.length);
      }
      if (phoneNumber.startsWith('00') && phoneNumber.slice(4, 5) === '0') {
        phoneNumber = phoneNumber.substring(0, 4) + phoneNumber.substring(5, phoneNumber.length);
      }
      if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+') && phoneNumber.startsWith('0')) {
        phoneNumber = '+41' + phoneNumber.slice(1, phoneNumber.length);
      }
      if (!phoneNumber.startsWith('00') && !phoneNumber.startsWith('+')) {
        phoneNumber = '+41' + phoneNumber;
      }
    }

    return phoneNumber;
  }
}
