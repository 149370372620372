export const getRandomValueBetween0And1With16DecimalPlaces = (): number => {
  const [generated16BitNumber] = window.crypto.getRandomValues(new Uint16Array(1));

  // The max generated value is 2¹⁶- 1 because we are passing Uint16Array.
  // To get random values between 0 to 1 we need to divide the result by max value.
  // If we use UInt16Array then we need to divide the value by 65535, what is Math.pow(2,16)-1

  // Because Math.random generates number between 0 and 1 that has 16 decimal places,
  // we need to round the generated number to 16 decimal places
  // (generated16BitNumber >= 65535 ? generated16BitNumber : 65534) is added, because number needs to be smaller than 255
  return Number(((generated16BitNumber >= 65535 ? 65534 : generated16BitNumber) / 65535).toFixed(16));
}
