export const preloadImage = (src: string) => {
  const img = new Image();
  img.src = src;
};

export const preloadSVGImages = () => {
  return [
    '/assets/images/alert/warning.svg',
    '/assets/images/alert/success.svg',
    '/assets/images/alert/info.svg',
    '/assets/images/edit_icon_white.svg',
    '/assets/images/edit_icon.svg',
    '/assets/images/more_options_icon.svg',
    '/assets/images/more_options_icon_white.svg',
    '/assets/images/new_mid_icon.svg'
  ].forEach(s => {
    preloadImage(s);
  });
};
