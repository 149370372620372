import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';

import { User } from '../../../shared/models/user';
import { environment } from '../../../../environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  availableLanguages = environment.languageSettings.availableLanguages;

  @Input() imagePath: string;
  @Input() selectedLanguage = 'en';
  @Input() isLoggedIn: boolean;
  @Input() userProfileUrl: string;
  @Input() user: User;

  @Output() changeLanguage = new EventEmitter<string>();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Output() openSettings = new EventEmitter();

  onLogin() {
    this.login.emit();
  }

  onLogout() {
    this.logout.emit();
  }

  onChangeLanguage(language: string) {
    this.changeLanguage.emit(language);
  }

  onOpenSettings() {
    this.openSettings.emit();
  }
}
