import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { APPLICATION_VERSION_SKIP } from '../../../shared/constants/app.constant';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-release-updates',
  templateUrl: './release-updates.component.html',
  styleUrls: ['./release-updates.component.scss']
})
export class ReleaseUpdatesComponent {
  @Input() version: string;
  @Input() updateInfo: string[];

  applicationVersionSkip = APPLICATION_VERSION_SKIP;
}
