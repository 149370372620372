<div class="toaster pr-3 pb-3 pt-3 pl-2 d-flex justify-content-around font-size-13 {{classType}}"
     [@fadeAnimation]="{value: animationState, params:
        { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
     (@fadeAnimation.done)="onFadeFinished($event)">

  <div class="border-left border-white pl-2 d-flex align-items-center">
    <div class="mr-1 pl-3 pr-3">
      <img [alt]="classType" class="toast-image-height" src="/assets/images/alert/{{classType}}.svg">
    </div>
  </div>
  <div class="text-white flex-grow-1 font-weight-600">
    <ng-container *ngIf="data.text as notificationText">
      <app-notification-info
        [notificationText]="notificationText"
        [language]="data.language"
        [linkWhiteColor]="true">
      </app-notification-info>
    </ng-container>
  </div>
  <div class="cursor-pointer text-white d-flex align-items-center font-weight-bold ml-3 font-size-14" (click)="close()">X</div>
</div>
