import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Subject } from 'rxjs';

import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from '../toast-config';
import { ToastRef } from '../toast-ref';
import { toastAnimations, ToastAnimationState } from '../toast-animation';
import { ToastInfoEnum } from '../toast.info.enum';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';
  classType: string;
  infoMessageTypes = ToastInfoEnum;
  onClosing: Subject<boolean>;

  private intervalId; // Timeout type

  constructor(
    public readonly data: ToastData,
    public readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public readonly toastConfig: ToastConfig
  ) {
    this.classType = data.type;
    this.onClosing = new Subject<boolean>();
  }

  ngOnInit() {
    this.intervalId = setTimeout(() => this.animationState = 'closing', this.toastConfig.closeAfter);
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
    this.onClosing?.unsubscribe();
  }

  close() {
    this.onClosing.next(true);
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
