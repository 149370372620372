export class PrismaXsConstant {
  public static PRISMA_XS_FIELD_WIDTH = 250;
  public static PRISMA_XS_FIELD_HEIGHT = 150;
  public static PRISMA_XS_DEFAULT_ROWS = 7;
  public static PRISMA_XS_DEFAULT_FIELDS = 2;
  public static GRID_BOX_PROPORTION = 1.6666666;
  public static GRID_MULTIPLAYER = 1.08111;
  public static GRID_MULTIPLAYER_12_ROWS = 1.445;
  public static PRISMA_XS_GRID_ENLARGER = 10;
  public static UPRIGHT_WIDTH = 53;
  public static ELEMENT_HEIGHT_OVER_THE_GRID = 70;
  public static MAX_HEIGHT_FOR_CONFIGURED_NUMBER_OF_ROWS = 700;
  public static KITS_IMAGE_LOCATION = '/assets/prisma-xs/kits';
  public static KITS_IMAGE_TYPE = 'jfif';
  public static VAT = 0.081;
  public static DEFAULT_SOCLE = 0;
  public static DEFAULT_CURRENCY = 'CHF';
  public static NSXM_KIT_TYPE_ID = 25;
  public static GRID_HIGHLIGHT_COLOR = {
    allowedToPlace: 'green',
    notAllowedToPlace: 'red'
  };
  public static CANT_BE_PLACED_IN_280_DEEP_ENCLOSURE = ['SZVX17RM7160', 'SZVX27RM7160', 'SZVX18RM7170', 'SZVX28RM7170', 'SZVX19RM7180', 'SZVX29RM7180'];
  public static OVERWRITE_CAN_GO_TROUGH_RULE = ['SZMT22220SE'];
  public static KITS_FOR_MEDIA_DEVICES = 22;
  public static ADDITIONAL_COMPONENTS_FOR_280_REFERENCES = ['SZNLP20', 'SZNLP21', 'SZNLP22', 'SZNLP23', 'SZNLP24', 'SZNLP25', 'SZNLP26', 'SZNKT25', 'SZNKT26'];

  public static MODULAR_KITS_FOR_UTILITY_METERING_ORDER_ID = 6;
  public static MODULAR_KITS_FOR_METERING_ORDER_ID = 7;
  public static MODULAR_KITS_FOR_PRAGMA_MULTI_MEDIA_ORDER_ID = 15;
}
