import { IPoint, IWidget, IWidgetOnGridOrientation, IWidgetSize, PositionSide } from '../models/prisma-xs.model';
import { IArticle, IBlockingKitsAbove } from '../models/article.model';
import { isPointBetween } from './elements-sizes-calculations.helper';
import { IRectangle, Rectangle } from '../../widget-grid/models/Rectangle.model';
import { PrismaXsConstant } from '../../shared/models/prisma-xs.constant';
import { KitGroupOrder } from '../../shared/models/prisma-xs.model';

export function getWidgetsPointsAndOrientation(widget: IWidget): IWidgetOnGridOrientation[] {
  const sides = [];
  sides.push({ point: { top: widget.top, left: widget.left }, orientationSide: PositionSide.RIGHT },
    { point: { top: widget.top, left: widget.left + widget.width }, orientationSide: PositionSide.LEFT });
  return sides;
}

export const getWidgetBottomPoint = (widget: IWidget): number => {
    return widget.top + widget.height;
};

export const closestUpperWidget = (allWidgetsAbove: IWidget[]): IWidget => {
  return allWidgetsAbove.reduce((first, second) => {
    return getWidgetBottomPoint(first) > getWidgetBottomPoint(second) ? first : second;
  });
};

export const closestLowerWidget = (allWidgetsBelow: IWidget[]): IWidget => {
  return allWidgetsBelow.reduce((first, second) => {
    return first.top < second.top ? first : second;
  });
};

export const isWidgetOnHigherPosition = (higherWidget: IWidget, point: IPoint): boolean => {
  return getWidgetBottomPoint(higherWidget) <= point.top;
};

export const isPointBetweenWidgetWidth = (point: IPoint, parentWidget: IWidget) => {
  return isPointBetween(point.left, parentWidget.left, parentWidget.left + parentWidget.width);
};

export const getWidgetHighlightColor = (availablePlacingGrabbingArticleOnGrid: boolean): string => {
  return availablePlacingGrabbingArticleOnGrid ?
    PrismaXsConstant.GRID_HIGHLIGHT_COLOR.allowedToPlace : PrismaXsConstant.GRID_HIGHLIGHT_COLOR.notAllowedToPlace;
};

const areaIsDirectlyAboveOrBelowWidget = (widget: IRectangle, placeArea: IRectangle): boolean => {
  const areaIsWithinBordersOfWidget = areaIsWithinBordersOfAnother(widget, placeArea);
  const widgetWithSeparatorIsPlacedOnBottomOfSelectedArea = widget?.top === placeArea?.top + placeArea?.height;
  // tslint:disable-next-line:max-line-length
  const widgetWithSeparatorIsPlacedOnTopOfSelectedArea = widget?.top + widget?.height === placeArea?.top;

  return (widgetWithSeparatorIsPlacedOnTopOfSelectedArea || widgetWithSeparatorIsPlacedOnBottomOfSelectedArea)
    && areaIsWithinBordersOfWidget;
}

export const hasBlockingWidgetBecauseOfFieldSeparator = (widgetsOnGrid: IArticle[], placeArea: Rectangle): boolean => {
  return widgetsOnGrid.findIndex(widget => widget?.rules?.placement?.cannotBePlacedOverHorizontalSeparator &&
    areaIsDirectlyAboveOrBelowWidget(widget?.widget, placeArea)) !== -1;
};

export const isRightAboveOrBelowWidget = (widgetArea: IWidget, parentWidget: IWidget): boolean => {
  if (!widgetArea || !parentWidget) {
    return false;
  }

  const topAreaPoint = widgetArea.top;
  const bottomAreaPoint = getWidgetBottomPoint(widgetArea);

  const parentWidgetTopPoint = parentWidget.top;
  const parentWidgetBottomPoint = getWidgetBottomPoint(parentWidget);

  return (widgetArea.left >= parentWidget.left && widgetArea.left < parentWidget.left + parentWidget.width)
    && (topAreaPoint === parentWidgetBottomPoint || bottomAreaPoint === parentWidgetTopPoint);
};

export const hasPlacementBlocking = (article: IArticle,
                                     placeArea: Rectangle,
                                     widgetsOnGrid: IArticle[],
                                     enclosureHeight: number): boolean => {
  // If kits with horizontal uprights can be placed on top or bottom of the metering kits
  // Only kits with width higher then 1 can come with horizontal uprights
  if (!article.rules?.upright?.allowGoTrough && placeArea?.width > 1 &&
    hasBlockingWidgetBecauseOfFieldSeparator(widgetsOnGrid, placeArea)) {
    return true;
  }

  if (article.rules?.placement?.cannotBePlacedOverHorizontalSeparator &&
    placementBlockedBecauseArticleIsOnTopOfHorizontalSeparator(widgetsOnGrid, placeArea)) {
    return true;
  }

  if (article.rules?.placement?.cannotBePlacedOnTopOrBottomOfEnclosure && kitIsOnTopOrBottomOfEnclosure(placeArea, enclosureHeight)) {
    return true;
  }

  return hasBlockingWidgetOnTopOrBottom(article?.rules?.placement?.blockingKitsAbove, widgetsOnGrid, placeArea);
};

export const hasBlockingWidgetOnTopOrBottom = (blockingKitsAboveRules: IBlockingKitsAbove,
                                               widgetsOnGrid: IArticle[],
                                               placeArea: Rectangle): boolean => {
  if (!blockingKitsAboveRules?.blockingKitsOrderIds?.length) {
    return false;
  }

  return blockingKitsAboveRules?.blockingKitsOrderIds?.some(blockingID => {
      const widgetsAboveOrBelow = widgetsOnGrid.filter(placedKit => isRightAboveOrBelowWidget(placeArea, placedKit.widget));

      return widgetsAboveOrBelow.some(widget => {
        if (blockingKitsAboveRules.exceptions.includes(widget.ref)) {
          return;
        }

        return widget.kitOrder === blockingID
      });
    }
  );
}

const areaIsWithinBordersOfAnother = (area: IRectangle, anotherArea: IRectangle): boolean => {
  return area?.left >= anotherArea?.left && area?.left < anotherArea?.left + anotherArea?.width;
}

export const placementBlockedBecauseArticleIsOnTopOfHorizontalSeparator = (widgetsOnGrid: IArticle[], placeArea: Rectangle): boolean => {
  return widgetsOnGrid
    .findIndex(widget => !widget.rules.upright.allowGoTrough &&
      widget.widget?.width > 1 &&
      areaIsDirectlyAboveOrBelowWidget(placeArea, widget?.widget)) !== -1;
};

export const kitIsOnTopOrBottomOfEnclosure = (placeArea: Rectangle, enclosureHeight: number) => {
  return placeArea?.top === 1 || placeArea?.top + placeArea?.height - 1 === enclosureHeight;
}

export const getUprightsCanGoTrough = (movingWidget: IArticle): boolean => {
  if (!movingWidget?.rules?.upright) {
    return true;
  }
  if (!('allowGoTrough' in movingWidget?.rules?.upright)) {
    return true;
  }
  return movingWidget.rules.upright.allowGoTrough;
};

export const uprightsCanGoTroughDevice = (placedKits: IArticle[], widgetUUID: string): boolean => {
  const movingWidget = placedKits.find(kit => kit.uuid === widgetUUID);
  return getUprightsCanGoTrough(movingWidget);
};

export const removeItemFromWidgets = (currentWidget: IWidget, articles: IArticle[]): IArticle[] =>  {
  return articles.filter(article =>
    article.widget.top !== currentWidget.top || article.widget.left !== currentWidget.left);
};

export const widgetOnGridPositionSize = (widget: IWidget): IWidgetSize => {
  return  {
    width: widget.left + widget.width - 1,
    height: widget.top + widget.height - 1
  };
};

export const generateWidgetOnGrid = (article: IArticle, uprightsCanGoTroughMovingKit): IArticle => {
  return {
    ...article,
    rules: {
      ...article.rules,
      upright: {
        ...article.rules?.upright,
        allowGoTrough: uprightsCanGoTroughMovingKit
      }
    }
  }
};

export const hasWidgetOnTheSameLeftLine = (widgetAreaLeft: number, kitWidget: IWidget) => {
  return widgetAreaLeft >= kitWidget.left && widgetAreaLeft < kitWidget.left + kitWidget.width;
}

export const getWidgetAbove = (widgetArea: IWidget, placedKits: IArticle[]): IArticle => {
  const topAreaPoint = widgetArea.top;

  return placedKits.find(kit => {
    const parentWidgetBottomPoint = getWidgetBottomPoint(kit.widget);
    return hasWidgetOnTheSameLeftLine(widgetArea.left, kit.widget) && topAreaPoint === parentWidgetBottomPoint;
  });
};

export const getWidgetBelow = (widgetArea: IWidget, placedKits: IArticle[]): IArticle => {
  const bottomAreaPoint = getWidgetBottomPoint(widgetArea);

  return placedKits.find(kit => {
    const parentWidgetTopPoint = kit.widget.top;

    return hasWidgetOnTheSameLeftLine(widgetArea.left, kit.widget) && bottomAreaPoint === parentWidgetTopPoint;
  });
};

export const isMeteringKit = (kitOrder: KitGroupOrder): boolean => {
  return kitOrder === KitGroupOrder.MODULAR_KITS_FOR_METERING || kitOrder === KitGroupOrder.MODULAR_KITS_FOR_UTILITY_METERING;
};
