import { InjectionToken, TemplateRef } from '@angular/core';

export class ToastData {
  type: ToastType;
  text?: string;
  language?: string;
  private template?: TemplateRef<any>;
  private templateContext?: {};
}

export type ToastType = 'warning' | 'info' | 'success';

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
  closeAfter: number;
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 60,
    right: 20,
  },
  animation: {
    fadeOut: 1000,
    fadeIn: 150,
  },
  closeAfter: 5000
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
