import { PrismaXsConstant } from '../../shared/models/prisma-xs.constant';
import { IElementSize } from '../models/grid-elements.model';
import { EnclosureTypesNamesConstants } from '../../ui/constants/enclosure-types-names.constants';

export const calculateSize = (parentHeight, parentWidth, rows: number, cols: number,
                              maxRows: number, maxFields: number): IElementSize => {
  const ratio = PrismaXsConstant.GRID_BOX_PROPORTION;
  let heightOfOne: number;
  let widthOfOne: number;

  // Checking if height of the enclosure is bigger then its width
  if (rows * PrismaXsConstant.PRISMA_XS_FIELD_HEIGHT > cols * PrismaXsConstant.PRISMA_XS_FIELD_WIDTH) {
    heightOfOne = parentHeight / maxRows;
    widthOfOne = heightOfOne * ratio;
  } else {
    widthOfOne = parentWidth / maxFields;
    heightOfOne = widthOfOne / ratio;

    const createdElementHeight = ((parentWidth / cols) / ratio) * rows;
    if (createdElementHeight > parentHeight) {
      heightOfOne = parentHeight / maxRows;
      widthOfOne = heightOfOne * ratio;
    }
  }

  return { width: widthOfOne, height: heightOfOne };
};

export const calculateParentElementDimensions = (parentElement: HTMLElement, marginOffset) => {
  return {
    height: parentElement.clientHeight - PrismaXsConstant.ELEMENT_HEIGHT_OVER_THE_GRID,
    width: parentElement.clientWidth - marginOffset
  };
};

const calculateRatio = (parentElementDimensions: IElementSize, childElementSize: IElementSize): number => {
  const widthRatio = parentElementDimensions.width / childElementSize.width;
  const heightRatio = parentElementDimensions.height / childElementSize.height;

  const ratio = childElementSize.width > childElementSize.height ? widthRatio : heightRatio;
  if (ratio * childElementSize.height > parentElementDimensions.height) {
    return heightRatio;
  }
  return ratio;
};

export const calculateDimensionsOfFittingElement = (parentElement: HTMLElement, maxRows: number, maxFields: number,
                                                    currentRows: number, currentFields: number,
                                                    marginOffset = 0): IElementSize => {

  const parentElementDimensions = calculateParentElementDimensions(parentElement, marginOffset);
  const createdEnclosureFieldSize = calculateSize(parentElementDimensions.height, parentElementDimensions.width,
    currentRows, currentFields, maxRows, maxFields);

  const createdEnclosureSize = {
    width: createdEnclosureFieldSize.width * currentFields,
    height: createdEnclosureFieldSize.height * currentRows
  };

  const maxEnclosureSize = {
    width: createdEnclosureFieldSize.width * maxFields,
    height: createdEnclosureFieldSize.height * maxRows
  };

  const ratio = calculateRatio(parentElementDimensions, createdEnclosureSize);
  return {
    width: maxEnclosureSize.width * ratio,
    height: maxEnclosureSize.height * ratio
  };
};

export const isPointBetween = (point: number, min: number, max: number): boolean => {
  return point > min && point < max;
};

export const getPrismaXsGridEnlarger = (rows: number, fields: number) => {
  let multiplier;

  switch (rows) {
    case 12:
      multiplier = PrismaXsConstant.GRID_MULTIPLAYER_12_ROWS;
      break;
    case 9:
      multiplier = PrismaXsConstant.GRID_MULTIPLAYER + 0.003;
      break;
    default:
      multiplier = PrismaXsConstant.GRID_MULTIPLAYER;
  }

  if (fields === 5) {
    multiplier -= 0.00555;
  }

  return multiplier;
};

export const getPrismaXsEnclosureWidthEnlarger = (height: number, maxRows: number) => {
  const heightOfOneNew = height / maxRows;
  return heightOfOneNew * PrismaXsConstant.GRID_BOX_PROPORTION;
};

export const calculateWidthHeightPrismaXS = (height: number, rows: number, fields: number) => {
  return {
    // tslint:disable-next-line:max-line-length
    enclosureWidth: (height + PrismaXsConstant.PRISMA_XS_GRID_ENLARGER) / getPrismaXsGridEnlarger(rows, fields),
    enclosureHeight: height + PrismaXsConstant.PRISMA_XS_GRID_ENLARGER
  };
};

const calculateHeightEnlarger = () => {
  return PrismaXsConstant.PRISMA_XS_GRID_ENLARGER * PrismaXsConstant.GRID_BOX_PROPORTION;
};


export const calculateWidthHeightConfiguration = (width: number, height: number, type: string, rows: number, fields: number) => {
  if (type === EnclosureTypesNamesConstants.PRISMA_XS) {
    return  calculateWidthHeightPrismaXS(height, rows, fields);
  } else {
    return {
      enclosureWidth: width + PrismaXsConstant.PRISMA_XS_GRID_ENLARGER,
      enclosureHeight: height + calculateHeightEnlarger(),
    };
  }
};

export const getZoomByDimensionPrismaXS = (zoomFactor: number): number => {
  return 50 * zoomFactor;
};

export const getEnclosureDimensionsBasedOnZoomFactor = (parentElement: HTMLElement,
                                                        factor: number,
                                                        configurationRows: number,
                                                        configurationFields: number,
                                                        maxRows: number,
                                                        maxFields: number): IElementSize => {
  const dimensions = calculateDimensionsOfFittingElement(parentElement, maxRows, maxFields, configurationRows, configurationFields, 30);
  // tslint:disable-next-line:max-line-length
  const createdEnclosureFieldSize = calculateSize(dimensions.height, dimensions.width, maxRows, maxFields, configurationRows, configurationFields);
  const zoomBy = getZoomByDimensionPrismaXS(factor);
  const currentHeight = createdEnclosureFieldSize.height * configurationRows;

  return {
    width: getPrismaXsEnclosureWidthEnlarger(currentHeight + zoomBy, maxRows) * maxFields,
    height: (currentHeight + zoomBy)
  };
};

// @TODO Cover with Unit Test
export const limitMaxWidthForImagePreview = (widthOfOneField: number, configurationRows: number): number => {
  let heightForConfiguredNumberOfRows = (widthOfOneField / PrismaXsConstant.GRID_BOX_PROPORTION) * configurationRows;
  heightForConfiguredNumberOfRows = heightForConfiguredNumberOfRows > PrismaXsConstant.MAX_HEIGHT_FOR_CONFIGURED_NUMBER_OF_ROWS ?
    PrismaXsConstant.MAX_HEIGHT_FOR_CONFIGURED_NUMBER_OF_ROWS : heightForConfiguredNumberOfRows;
  const heightOfOneField = heightForConfiguredNumberOfRows / configurationRows;

  return heightOfOneField * PrismaXsConstant.GRID_BOX_PROPORTION;
}
