import { Pipe, PipeTransform } from '@angular/core';
import { DocumentLogoType } from '../../enclosure/models/documents-types.constants';

@Pipe({
  name: 'companyLogoProportion'
})
export class CompanyLogoProportionPipe implements PipeTransform {

  transform(proportion: number, logoOption): boolean {
    if(proportion && (proportion < 2.8 || proportion > 3) && logoOption === DocumentLogoType.CUSTOM) {
      return true;
    }
    return false;
  }

}
