import { IEnclosureSpecs } from './enclosure.model';
import { IAdjustableGrids, PinStatus } from '../../enclosure/models/grid.model';
import { KitType } from './prisma-xs.model';
import { IApplicationGroups } from './device.model';
import { IRules } from '../../prisma-xs/models/article.model';
import { IDevicePolesIDs } from '../../enclosure/models/device-data.model';
import { ISLDProduct } from '../../enclosure/models/sld.model';

export interface IProduct {
  applicationGroupId?: number;
  applicationGroup?: IApplicationGroups;
  id: number;
  additionalBOMEntryId?: number;
  productId: number;
  enumber: number;
  name: string;
  pDescription: string;
  reference: string;
  productSupportSpec: IProductSupportSpec;
  productSupportSpecId: number;
  price: number;
  category: string;
  productScopeId: number;
  quantity: number;
  totalPrice: number;
  productRangeId: number;
  productRange: IProductRange;
  enclosureSpecs: IEnclosureSpecs;
  isEnclosure: number;
  isMCB: number;
  isRCCB: number;
  isRCBO: number;
  groupId: number;
  poleId: number;
  poleName: string;
  ampere: string;
  kurve: string;
  type: string;
  productTypeId: number;
  type2: string;
  leakageCurrent: string;
  modules: string;
  feederId: number;
  deliverToCustomer: boolean;
  deliverToPanelBuilder: boolean;
  poleContactComposition: string;
  /**
   * @deprecated
   */
  poleInfo: IProductPole;
  productCatalogueTitle?: string;
  availableFunction: string;
  specification: string;
  width: number;
  pattern?: PinStatus[];
  sizeRules?: IProductSize;
  columnNumber?: number;
  rowNumber?: number;
  isPMC?: boolean;
  pragmaProduct?: IPragmaProduct;
  prismaProduct?: IPrismaProduct;
  productFunctions?: IProductFunctions[];
  poleContactCompositionSpec: IPoleContactCompositionSpec;
  userDescription: string;
  status?: ProductStatus;
  isCustomDevice?: boolean;
  specialDevicePlacement?: ISpecialDevicePlacement;
  rotated?: boolean;
  bridge?: IBridge;
  onlySuitableFor280mmPrismaXS?: boolean;
  newProduct?: boolean;
  sld: ISLDProduct;
  gridPlacementRules: IGridPlacementRules;
  presentationRules: IPresentationRules;
  kitRules?: IKitRules;
  pragmaMultiCustomized?: boolean;
  prismaXS?: boolean;
  productCharacteristicsRules: IProductCharacteristicsRules;
  bomRules: IBomRules;
  replacingProduct?: IProduct;
}

export interface IBridge {
  bridgeWidth: number,
  placeableDevices: IDevicePolesIDs[]
}

export interface ISpecialDevicePlacement {
  onlyPlaceableOnKitsReferences: string[];
  numberOfSlots: number;
  deviceSpecWidth: number;
  labelOffset: number;
  deviceSpecHeight: number;
  offsetX: number;
  kitOffset: IKitOffset[];
}

export interface IKitOffset {
  reference: string,
  offsetX: number,
  offsetY: number
}

export interface IPoleContactCompositionSpec {
  poleId: number;
  poleName: string;
}
export interface IPragmaProduct {
  fields: number;
  rows: number;
}

export interface IProductFunctions {
  functionId: number;
  function: string;
  value: string;
}

export interface IPrismaProduct {
  fields: number;
  kitType: KitType;
  rows: number;
  rules: IRules
  kitTypeId?: number;
}

export interface IProductSize {
  minRequiredNumberOfModules: number;
  minSize: number;
}

export interface IProductSupportSpec {
  productSupportSpecId: number;
  reference: string;
  supportName: string;
  length: number;
  height: number;
  width: number;
  coverName?: string;
  positionX?: number;
  positionY?: number;
  productSupportSpecPoints: IProductSupportSpecPoint[];
}

export interface IProductRange {
  applicationGroup?: IApplicationGroups;
  description?: string;
  productRangeId: number;
  name: string;
  label: string;
}

export interface IProductPole {
  poleId: number;
  poleName: string;
  image: string;
}

export interface IProductSupportSpecPoint {
  positionX: number;
  positionY: number;
  offsetX?: number;
  modules?: number;
  positionLabelY?: number;
  width?: number;
  supportedRangesList?: number[];
  adjustedGridToDevices?: IAdjustableGrids[];
  specialFieldPlacementRules?: IUnavailablePlacement[];
  removeRailsForProductRangeIDs?: number[];
  allowsPatchpanels?: boolean;
  pragmaMultiCustomizedPowerTagNotAllowed?: boolean;
}

export enum ProductStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  DEPRECATED = 2,
}

export interface IProductIDs {
  productRangeId: number;
  productPoleId: number;
}

export interface IUnavailablePlacement {
  enclosureType: string,
  unavailableDevicesReferences: IProductIDs[];
  unavailableSlots: number[];
}

export interface IPragmaSpecialPlacementRulesOnKits {
  specialPlacementRulesPmcUpContactorDevices: IUnavailablePlacement;
  specialPlacementRulesPmcApContactorDevices: IUnavailablePlacement;
}

export interface IPrismaSpecialPlacementRulesOnKits {
  specialPlacementRulesPrismaXSWallMountingContactorDevices: IUnavailablePlacement;
  specialPlacementRulesPrismaXSFloorStandingContactorDevices: IUnavailablePlacement;
}

export interface IGridPlacementRules {
  dragAndDropAllowed: boolean;
  kitsPlacementWithSpecialRules: boolean;
  deviceCanStandOnBridge: boolean;
  showLabelOnCover: boolean;
  hasRightExtension: boolean;
  deviceCanBePlacedNextToFiVigi: boolean;
  isDeviceSkippedFromSld: boolean;
  placeableOnMultimediaRails: boolean;
  halfPointsPlacement: number[];
  rightSideExtension?: IRightSideExtension;
  numberOfCombBusBarCuttingSlots?: number;
  deviceComesWithSpacer: boolean;
  compatiblePowerTagDevices: ICompatiblePowerTag[];
}

export interface IPresentationRules {
  showAmperesOnLabel: boolean;
  productRatedCurrentDependentFromVoltage: boolean;
  showSideMenuApplicationGroup: boolean;
  deviceWithRemoveableBridge: boolean;
  deviceProductCharacteristicsLink: string;
  addReferenceToConfigurationTitle: boolean;
  hasShieldedAndUnshieldedVariant: boolean;
  sldReplacingName?: ISldReplacingName[];
  devicesSpecialCoverReference?: string;
  showCompactINSRatedCurrentRestrictionInfo: boolean;
  specialLabelOffset?: ISpecialLabelOffset;
  hasLimitedAvailabilityDeviceInfo?: boolean;
  hasCombBusBarRecommendationOption?: boolean;
}

export interface IProductCharacteristicsRules {
  isCombBusBar: boolean;
  isPowerTag: boolean;
  isElectricalClamp: boolean;
  isCustomDevice: boolean;
  isDirectMetering63A: boolean;
  isDirectMetering125A: boolean;
  isResi9CX: boolean;
  isResi9KV: boolean;
  isMeteringDevice: boolean;
  isTimeSwitch: boolean;
  isCompactNsxm: boolean;
  isCompactNsx: boolean;
  isCompactNsxDummy: boolean;
  isUniversalDevice: boolean;
  isSpacer: boolean;
  isSpd: boolean;
  isRCCBFIClarioiLD403PN: boolean;
  isFiVigiClarioIc40: boolean;
  isBridgedContactor: boolean;
  isDirectMeteringCT: boolean;
}

export interface ISldReplacingName {
  deviceFromRight: IDevicePolesIDs,
  replacingNameForDeviceFromRight: ILanguages
}

export interface ILanguages {
  en: string,
  de: string,
  fr: string,
  it: string
}

export interface IRightSideExtension {
  extensionWidth: number;
  placeableDevicesFromRight: IDevicePolesIDs[];
}

export interface IKitRules {
  kitWithCutOuts: boolean;
  prismaXSFieldWithSpecialRules: boolean;
  pragmaSpecialPlacementRulesOnKits?: IPragmaSpecialPlacementRulesOnKits;
  prismaSpecialPlacementRulesOnKits?: IPrismaSpecialPlacementRulesOnKits;
}

export interface IBomRules {
  canTransferToWholesaler: boolean;
  priceBeforeDiscount: number;
}

export interface ISpecialLabelOffset {
  offsetInPercents: number;
  deviceRotated: boolean;
}

export interface ICompatiblePowerTag {
  productPoleId: number;
  productRangeId: number;
  positionRelativeToConnectedDevice: number
}
