import { IEnclosure as IOriginalEnclosure } from './enclosure.model';
import { IEnclosureDB } from '../../prisma-xs/models/enclosure-xs.model';
import { uuidv4 } from '../helpers/grid-devices.helper';
import { PrismaXsConstant } from './prisma-xs.constant';
import { PragmaCustomizedMountingType } from '../../enclosure/models/pragma-customized.model';

export enum EnclosureType {
  WallMounted = 'wallMounting',
  FloorStanding = 'floorStanding'
}
export enum EnclosureIP {
  IP30 = 'IP30',
  IP43 = 'IP43',
  IP55 = 'IP55',
}

export enum KitType {
  'ModularKits' = 0,
  'CompleteFields' = 1,
  'Uprights' = 2,
  'Separators' = 3,
  'CompleteEnclosures' = 4
}

export enum KitGroupOrder {
  MODULAR_KITS_FOR_UTILITY_METERING = PrismaXsConstant.MODULAR_KITS_FOR_UTILITY_METERING_ORDER_ID,
  MODULAR_KITS_FOR_METERING = PrismaXsConstant.MODULAR_KITS_FOR_METERING_ORDER_ID,
  KITS_FOR_COMPLETE_MULTI_MEDIA_FIELDS = 9,
  MODULAR_KITS_FOR_PRAGMA_MULTI_MEDIA = PrismaXsConstant.MODULAR_KITS_FOR_PRAGMA_MULTI_MEDIA_ORDER_ID
}

export interface IEnclosureConfigDetails {
  ip: EnclosureIP;
  dimensions?: number;
  hasDoor?: boolean;
}

export interface IEnclosure {
  type: EnclosureType;
  id?: string;
  details: IEnclosureConfigDetails;
  rows: number;
  fields: number;
}

export class ConfiguratorModel implements IEnclosure {
  enclosureGuid?: string;
  type: EnclosureType;
  details: IEnclosureConfigDetails;
  socleHeight?: number;
  minRows?: number;
  minFields?: number;
  rows: number;
  fields: number;
  isNew = true;
  selectedCurrent?: string;
  is210mmDepthAllowed?: boolean;
  mountingType?: PragmaCustomizedMountingType;

  constructor() {
    this.enclosureGuid = uuidv4();
  }
}

export interface IRule {
  ipType: EnclosureIP;
  rule: ISizeRule;
}

export interface ISizeRule {
  rows: ISizeConstraint;
  fields: ISizeConstraint;
  enclosureRuleExceptions?: IRuleException[];
}

export interface IEnclosureRules {
  enclosureType: EnclosureType;
  rules: IRule[];
}

export interface ISizeConstraint {
  min: number;
  max: number;
}

export interface IRuleException {
  maxRows?: number;
  minRows?: number;
  minFields?: number;
  maxFields?: number;
}

export interface IPrismaXSEnclosure extends IOriginalEnclosure {
  configuration?: ConfiguratorModel;
}

export interface IPredefinedPrismaXSEnclosureSettings {
  enclosureSpecs?: IEnclosureDB;
}

export interface Dimensions {
  height: number;
  width: number;
  depth: number;
}

export interface IEnclosureStockLevel {
  rowsNumber: number;
  fieldsNumber: number;
  stockLevel: number;
}
