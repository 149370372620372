<div class="modal fade" #settingsModal data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content col-md-12 p-0 m-0">
      <div class="modal-header border-0 p-0">
        <div class="col-md-6 bg-ultra-light-grey color-black pl-3 py-3">
          <span class="modal-title font-size-xxl font-weight-bolder" id="exampleModalLabel">
            <ng-container i18n="@@app.settings_logo_page">Document Settings</ng-container>
          </span>
        </div>
        <div class="col-md-6 py-3">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="col-md-12">
        <form [formGroup]="personalDetailsForm">
          <div class="modal-body p-0">
            <div class="row p-0">
              <div class="col-md-6 bg-ultra-light-grey">
                <app-uploadlogo-image
                  [errorMessage]="errorMessage"
                  [isLoading]="isLoading"
                  [image]="personalDetailsForm.value.image | async"
                  [logoLabel]="logoLabel"
                  [logoOptionSelected]="logoOptionSelected"
                  [imageProportion]="imageProportion"
                  (imageSelectedEmit)="onImageSelect($event)"
                  (clearImageEmit)="clearImage()"
                ></app-uploadlogo-image>
              </div>
              <div class="col-md-6">
                <app-uploadlogo-personal-details
                  [isContactChecked]="isContactChecked"
                  [isUpdateBtnLoading]="isUpdateBtnLoading"
                  [personalDetailsForm]="personalDetailsForm"
                  [detailsLabel]="detailsLabel"
                  [addressOptionSelected]="addressOptionSelected"
                  (focusOutPhone)="regeneratePhoneNumber($event)"
                  (toggleContactEmit)="toggleContactInformation()"
                  (updateContactInformationEmit)="saveContactInformation()"
                  (addressSelected)="selectCompanyAddress($event)"
                ></app-uploadlogo-personal-details>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
