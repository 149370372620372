export const youtubeLinks = {
  DE: 'https://www.youtube.com/hashtag/fellerdisposuite',
  IT: 'https://www.youtube.com/hashtag/fellerdisposuite',
  FR: 'https://www.youtube.com/hashtag/fellerdisposuite'
};

export const sldGuideTourLink = {
  DE: 'https://www.youtube.com/watch?v=oLZZkOv8CV0&list=PLSbybXVYGpSBP6RWiem7R2hWgjP0uP3MU',
  IT: 'https://www.youtube.com/watch?v=R5l7a_XtakI&list=PLSbybXVYGpSCgdqROnmtH-Rh0dYNiq52k',
  FR: 'https://www.youtube.com/watch?v=t6RH4JOHFzU&list=PLSbybXVYGpSAyEeC5nBwRjXQfNW0mK15X'
};

export const WEBINAR_LINK = {
  DE: 'https://youtu.be/N31TZzsCqDE',
  FR: 'https://youtu.be/4CU-AfHGhVM?si=q-k1x9F98gCh9F93',
};

export const NO_IMAGE = '/assets/no.png';
/* Version when new update mechanism is deployed */
export const APPLICATION_VERSION_SKIP = '1.3.1';
export const AUTH0_ROUTE = 'auth0-continue';

export const combBusBarRecommendationLink = {
  DE: 'https://mam.feller.ch/public/M-1725969505',
  IT: 'https://mam.feller.ch/public/M-1725968834',
  FR: 'https://mam.feller.ch/public/M-1725956222'
}
