import { Injectable } from '@angular/core';

import { IBomModel } from '../../prisma-xs/models/bom.model';

@Injectable({
  providedIn: 'root'
})
export class WhiService {
  public readonly hookUrl = 'hookUrl';

  storeHookUrl(hookUrl: string) {
    sessionStorage.setItem(this.hookUrl, hookUrl);
  }

  getHookUrl() {
    return sessionStorage.getItem(this.hookUrl);
  }

  mapProductToEldridge10(bomItem: IBomModel, quantity: number) {
    return {
      reference: bomItem.article.ref,
      description: bomItem.article.description,
      quantity: (bomItem.quantity * quantity).toString()
    }
  }
}
