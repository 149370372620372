import { Pipe, PipeTransform } from '@angular/core';

import { combBusBarRecommendationLink } from '../../shared/constants/app.constant';

@Pipe({
  name: 'combBusbarRecommendationLink'
})
export class CombBusbarRecommendationLinkPipe implements PipeTransform {

  transform(selectedLanguage: string): string {
    switch (selectedLanguage) {
      case 'de':
        return combBusBarRecommendationLink.DE;
      case 'it':
        return combBusBarRecommendationLink.IT;
      case 'fr':
        return combBusBarRecommendationLink.FR;
      default:
        return combBusBarRecommendationLink.DE;
    }
  }

}
