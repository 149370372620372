import { Injectable } from '@angular/core';
import Gleap from 'gleap';

import { environment } from '../../../environments/environment';
import { FeedbackButtonComponent } from '../../ui/components/feedback-button/feedback-button.component';
import { getRandomValueBetween0And1With16DecimalPlaces } from '../helpers/math-operations.helper';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private feedbackButton: FeedbackButtonComponent;

  init(userName: string, email: string, language: string, button: FeedbackButtonComponent) {
    this.feedbackButton = button;

    Gleap.initialize(environment.userFeedback);
    Gleap.setLanguage(language);
    Gleap.setCustomData('email', email);
    Gleap.setCustomData('name', userName);

    Gleap.identify(getRandomValueBetween0And1With16DecimalPlaces().toString(), {
      name: userName,
      email,
    });

    this.feedbackButton?.toggleVisibility();

    Gleap.on('close', () => {
      setTimeout(() => {
        if (Gleap.isOpened() && this.feedbackButton.isVisible()) {
          this.toggleButton();
        }

        if (!Gleap.isOpened()) {
          if (!this.feedbackButton.isVisible()) {
            this.toggleButton();
          }
        }
      }, 250);
    });
  }

  toggleButton() {
    this.feedbackButton?.toggleVisibility();
  }

  show() {
    Gleap.open();
  }

  hide() {
    Gleap.hide();
  }
}
