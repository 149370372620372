<p *ngIf="version">
  <ng-container i18n="@@app.new_version">New version Release</ng-container> ({{' ' + version  + ' '}}) <ng-container i18n="@@app.is_available">containing new features is available</ng-container>:
</p>
<div>
  <ul>
    <li *ngFor="let info of updateInfo">{{info}}</li>
  </ul>
</div>

<!-- This is exception for deployment of 1.3.1 release as it is beginning of this functionality -->
<ng-container *ngIf="version !== applicationVersionSkip">
  <p i18n="@@app.we_just_found_out_that_there">Click OK to reload page</p>
</ng-container>
