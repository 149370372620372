import { ChangeDetectionStrategy, Component, ElementRef, Output, ViewChild, EventEmitter } from '@angular/core';

import { elementIsShown } from '../../helpers/elements.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent {
  @ViewChild('feedback') private feedbackEl: ElementRef;
  @Output() clicked = new EventEmitter<void>();

  onClick() {
    this.toggleVisibility();
    this.clicked.emit();
  }

  toggleVisibility() {
    this.feedbackEl.nativeElement.classList.toggle('show');
  }

  isVisible() {
    return elementIsShown(this.feedbackEl);
  }

  isHidden() {
    return !elementIsShown(this.feedbackEl);
  }

}
