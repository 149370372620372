<div class="d-flex flex-column h-100">
  <div class="flex-height-65">
    <app-ui-header
      [imagePath]="imagePath"
      [selectedLanguage]="selectedLanguage"
      [isLoggedIn]="isLoggedIn$ | async"
      [userProfileUrl]="userProfileUrl"
      [user]="userInformation$ | async"
      (login)="login()"
      (logout)="logout()"
      (openSettings)="openSettings()"
      (changeLanguage)="onChangeLanguage($event)">
    </app-ui-header>
  </div>
  <div class="overflow-auto window-max-width ml-auto mr-auto w-100 flex-1-1-0">
    <router-outlet></router-outlet>
  </div>
  <div class="flex-height-35">
    <ng-container *ngIf="isLoggedIn$ | async">
      <app-ui-footer
        [selectedLanguage]="selectedLanguage | fallbackEnToDe"
      ></app-ui-footer>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="isLoggedIn$ | async">
  <app-ui-confirmation-modal
    #updateConfirmation
    [hideCancelButton]="true"
    modalTitle="New version is available"
    i18n-modalTitle="@@app.new_version_is_available"
    (confirm)="onUpdateVersionConfirmed()"
  >
    <app-release-updates [version]="updateVersion" [updateInfo]="updateInfo"></app-release-updates>
  </app-ui-confirmation-modal>
</ng-container>

<app-guest-information #guestInformationComponent (login)="loginGuest()"></app-guest-information>

<app-ui-feedback-button #feedbackButtonComponent (clicked)="showFeedbackButton()"></app-ui-feedback-button>

<ng-container *ngIf="isLoggedIn$ | async">
  <app-uploadlogo #settingsComponent
                  [logoLabel]="'headerSectionCB'"
                  [detailsLabel]="'headerDetailsSectionCB'"
  ></app-uploadlogo>
</ng-container>
