<div class="footer w-100 text-white">
  <div class="row m-0 px-5 expanded-area w-100 position-fixed overflow-hidden"
       [ngStyle]="{'height':isOpened ? '135px' : '0px'}">
    <div class="w-100 window-max-width ml-auto mr-auto row">
      <div class="col-6 pl-5 mt-4">
        <p class="se-font-title" i18n="@@app.contact">Contact</p>
        <div class="row mt-3">
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-2">
                <img alt="Phone" class="mt-2" src="/assets/images/phone.svg" width="25px"/>
              </div>
              <div class="col-10 se-text">
                <ng-container i18n="@@feller_customer_service_center">Feller Customer Service Center</ng-container>
                <br>
                +41 844 72 73 74
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-2">
                <a class="cursor-pointer" href="mailto:customercare.feller@feller.ch">
                  <img alt="Mail" class="mt-2" src="/assets/images/mail.svg" width="25px"/>
                </a>
              </div>
              <div class="col-10 se-text">
                <ng-container i18n="@@app.email">E-Mail</ng-container><br>
                <a href="mailto:customercare.feller@feller.ch">
                  <p class="se-link">customercare.feller&#64;feller.ch</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 pl-5 mt-4">
        <div class="row mt-2">
          <div class="col-6">
            <p class="se-font-title" i18n="@@app.service">Service</p>
            <div class="mb-2"><a class="se-link" href="https://www.feller.ch/{{selectedLanguage}}/Produktangebot" target="_blank" i18n="@@app.product_range">Product range</a></div>
            <div class="mb-2"><a class="se-link" href="http://online-katalog.feller.ch/download/index.php?sc_lang={{selectedLanguage}}"
                                 target="_blank" i18n="@@app.download_center">Download Center</a></div>
            <div class="mb-2"><a class="se-link" href="https://online-katalog.feller.ch"
                                 target="_blank" i18n="@@app.online_catalog">Online-Catalog</a></div>
          </div>
          <div class="col-6 pt-1">
            <div class="mb-2 color-black">
              <a class="se-link pr-3 border-right border-dark" href="https://www.feller.ch/{{selectedLanguage}}/Unternehmen/AVB" target="_blank" i18n="@@app.avb">AVB</a>
              <a class="se-link pl-3" href="https://www.feller.ch/{{selectedLanguage}}/Unternehmen/Nutzungsbedingungen" target="_blank" i18n="@@app.terms_and_conditions">Terms and conditions</a>
            </div>
            <div class="mb-2"><a class="se-link" href="https://www.feller.ch/{{selectedLanguage}}/privacy_notice_professional_users"
                                 target="_blank" i18n="@@app.data_protection">Data protection</a></div>
            <div class="mb-2"><a class="se-link" href="https://www.feller.ch/{{selectedLanguage}}/Unternehmen/DSGVO" target="_blank" i18n="@@app.gdpr">GDPR</a>
            </div>
            <div class="mb-2"><a class="se-link" href="https://www.feller.ch/{{selectedLanguage}}/Unternehmen/Impressum" target="_blank" i18n="@@app.impressum">Impressum</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex align-items-center h-100 window-max-width ml-auto mr-auto">
    <div class="col-4 pl-4 font-size-12 truncate">&#169;{{currentYear}} Feller AG, Horgen <ng-container i18n="@@app.all_rights_reserved">All rights reserved</ng-container> V{{ version }}</div>
    <div class="col-4 font-size-12">
      <div class="row d-flex justify-content-center cursor-pointer w-25 my-0 mx-auto" (click)="toggleFooter()">
        <div class="bg-brand-bright d-flex px-2 py-1 border-rounded align-items-center justify-content-center mb-2-custom">
          <span class="pr-2 mb-2-custom"><img alt="" [ngClass]="{'rotated': isOpened}" src="/assets/images/expand_icon.svg"/></span>
          <div *ngIf="isOpened" class="pt-1 text-uppercase font-size-10 mb-2-custom" i18n="@@app.hide">Hide</div>
          <div *ngIf="!isOpened" class="pt-1 text-uppercase font-size-10 mb-2-custom" i18n="@@app.show">Show</div>
        </div>
      </div>
    </div>
    <div class="col-4 pr-4 d-flex float-right justify-content-end">
      <a href="https://www.youtube.com/channel/UCcrBkHSuMiQEfUKRunfvUAA" target="_blank"> <img alt="Youtube" class="float-right cursor-pointer" src="/assets/images/youtube.svg" width="25px"/></a>
      <a href="https://www.linkedin.com/company/feller-ag/" target="_blank"><img alt="Linkedin" class="float-right ml-2 cursor-pointer" src="/assets/images/linkedin.svg" width="25px"/></a>
      <a href="https://www.xing.com/company/feller" target="_blank"><img alt="Xing" class="float-right ml-2 cursor-pointer" src="/assets/images/xing.svg" width="25px"/></a>
    </div>
  </div>
</div>

