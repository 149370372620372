import { TemplateRef } from '@angular/core';

import { IRectangle, Rectangle } from '../../widget-grid/models/Rectangle.model';
import { IArticle } from './article.model';
import { IPlacedDevice } from '../../enclosure/models/grid.model';

export interface IGroupInCatalogue {
  group: ITypeGroup;
  expanded: boolean;
}

export interface IGridPosition {
  id: number;
  size: {
    top: number,
    left: number
  };
  centerCoordinates: IPosition;
}

export interface IPosition {
  x: number;
  y: number;
}

export enum EnclosureType {
  WallMounted = 'wallMounting',
  FloorStanding = 'floorStanding'
}

export enum SearchFlags {
  Ref = 'Ref:',
  Enu = 'Enu:',
  Des = 'Des:'
}

export interface ISearchOptionFlags {
  flag: string;
  field: string;
}

export const SearchOptionFlags: ISearchOptionFlags[] = [
  {
    flag: SearchFlags.Ref,
    field: 'ref'
  },
  {
    flag: SearchFlags.Enu,
    field: 'eNumber'
  },
  {
    flag: SearchFlags.Des,
    field: 'description'
  }
];

export enum EnclosureIP {
  IP30 = 'IP30',
  IP43 = 'IP43',
  IP55 = 'IP55',
}

export interface IEnclosure {
  type: EnclosureType;
  id?: string;
  details: IEnclosureConfigDetails;
  rows: number;
  fields: number;
}

export interface Enclosure {
  enclosureId?: number;
  enclosureGuid?: string;
  configurationType: EnclosureType;
  configurationIp: EnclosureIP;
  configurationDimensions: number;
  configurationHasDoor: boolean;
  socleHeight?: number;
  configurationRows: number;
  configurationFields: number;
  configurationDateCreated?: string;
}

export interface IEnclosureConfigDetails {
  ip: EnclosureIP;
  dimensions: number;
  hasDoor: boolean;
}

export interface IRule {
  ipType: EnclosureIP;
  rule: ISizeRule;
}

export interface ISizeRule {
  rows: ISizeConstraint;
  fields: ISizeConstraint;
  enclosureRuleExceptions?: IRuleException[];
}

export interface IEnclosureRules {
  enclosureType: EnclosureType;
  rules: IRule[];
}

export interface ISizeConstraint {
  min: number;
  max: number;
}

export interface IRuleException {
  maxRows?: number;
  minRows?: number;
  minFields?: number;
  maxFields?: number;
}

export interface IWidgetSize {
  width: number;
  height: number;
}

export interface ITypeGroup {
  name: string;
  kitTypeId?: number;
  image: string;
  order?: number;
  articles: IArticle[];
}

export interface IWidget extends IRectangle {
  top: number;
  left: number;
  height: number;
  width: number;
  selected?: boolean;
}

export interface IEnclosureMinMax {
  minFields: number;
  minRows: number;
  maxFields: number;
  maxRows: number;
}

export interface IBlockingWidgets {
  upper: IWidget;
  lower: IWidget;
  isBetweenBordersOfAnyKit: boolean;
}

export interface IChangeWidgetPosition {
  widget: Rectangle;
  index: number;
  productType: string;
}

export interface IContextMenuGridEvent {
  event: MouseEvent;
  article?: IArticle;
  placedDevice?: IPlacedDevice;
  userMenu: TemplateRef<any>;
}

export enum ContextMenuActionType {
  SHOW_INFO = 'Show Info',
  COPY = 'Copy',
  PASTE = 'Paste',
  CANCEL = 'Cancel Copy',
  DELETE = 'Delete',
  COPY_SELECTED = 'Copy Selected',
  PASTE_WITH_KEYBOARD = 'Paste With Keyboard'
}

export interface IContextMenuGridAction {
  type: ContextMenuActionType;
  uuid?: string;
  mousePosition?: IPoint;
}

export interface ILanguageObject {
  [index: string]: any;
}

export interface IPoint {
  top: number;
  left: number;
}

export interface IWidgetOnGridOrientation {
  point: IPoint;
  orientationSide: PositionSide;
}

export enum PositionSide {
  LEFT = 0,
  RIGHT = 1,
  UP = 2,
  DOWN = 3
}

export interface ISegregationOnGrid {
  segregation: number[];
  unusedSegregations: number[];
}

export enum Orientation {
  HORIZONTAL = 0,
  VERTICAL = 1,
  METERING_HORIZONTAL = 2
}

export interface ISegregation {
  orientation: Orientation;
  size: number;
}
