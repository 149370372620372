import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DataService } from './data.service';
import { DocumentLogoType } from '../../enclosure/models/documents-types.constants';
import { User, UserLogo } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersLogoService {
  private logoUrl = new Subject<string>();

  constructor(private readonly dataService: DataService) { }

  getUsersLogo(): Observable<string> {
    return this.logoUrl as Observable<string>;
  }

  clearUsersLogo() {
    this.logoUrl.next(null);
  }

  private getCustomLogoUrl() {
    this.dataService.getUsersCompanyLogo(environment.apiEndpoint + '/api/v1/file/company_logo_url')
      .subscribe(logo => {
        this.logoUrl.next(logo);
      });
  }

  uploadLogo(logoFileToUpload: File): Observable<number> {
    return this.dataService.postCompanyLogo(logoFileToUpload, environment.apiEndpoint + '/api/v1/file/upload_users_company_logo')
      .pipe(
        catchError(err => {
          return throwError(err);
        }),
        map((logo: UserLogo) => {
          this.logoUrl.next(logo.logoUrl);
          return logo.proportion;
        })
      )
  }

  updateUsersLogoBasedOnOptionSelected(user: Partial<User>) {
    switch (user.useCompanyLogo) {
      case DocumentLogoType.CUSTOM: {
        this.getCustomLogoUrl();
        return;
      }
      case DocumentLogoType.YOUR_COMPANY:
        this.logoUrl.next(user?.companyDetails?.logoUrl);
        return;
      default:
        return '';
    }
  }
}
