import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { PageNotFoundComponent } from './home/pages/page-not-found/page-not-found.component';
import { Auth0ContinueComponent } from './home/pages/auth0-continue/auth0-continue.component';
import { AUTH0_ROUTE } from './shared/constants/app.constant';
import { LanguageLoadGuard } from './shared/guards/language-load.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    // @TODO implement this behavior correctly
    // canLoad: [LanguageLoadGuard]
  },
  {
    path: 'project',
    loadChildren: () => import('./project-layer/project-layer.module').then(m => m.ProjectLayerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'project/:id/enclosure',
    loadChildren: () => import('./enclosure/enclosure.module').then(m => m.EnclosureModule),
    canActivate: [AuthGuard]
  },
  {
    path: AUTH0_ROUTE,
    component: Auth0ContinueComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
