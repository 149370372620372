<div #confirmationModal class="modal fade" data-backdrop="static" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="border-radius-0 modal-content">
      <div class="modal-header text-uppercase py-2 font-size-16">
        <div class="modal-title">{{modalTitle}}</div>
        <button type="button" class="close" (click)="closeModal()">&times;</button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer pt-1 border-0">
        <ng-container *ngIf="!hideCancelButton">
          <button type="button" (click)="closeModal()" class="btn btn-light btn-lg text-uppercase border-rounded font-weight-normal mx-2" i18n="@@app.cancel">Cancel</button>
        </ng-container>
        <button type="button" appDisableMultipleClick class="btn btn-lg text-uppercase border-rounded font-weight-normal mx-2"
                [ngClass]="redConfirmationButton ? 'btn-danger' : 'btn-primary'"
                (click)="onConfirm()">
          <ng-container *ngIf="redConfirmationButton else okText">
            <span i18n="@@app.confirm_delete">Delete</span>
          </ng-container>
          <ng-template #okText>
            <span i18n="@@app.ok">Ok</span>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
</div>
