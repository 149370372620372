import { User as Auth0User } from '@auth0/auth0-spa-js';

export class User extends Auth0User {
  showUpdate?: string;
  lang?: string;
  useSecondaryDetails?: boolean;
  useCompanyLogo?: number;
  companyAddressType?: number;
  avatar?: string;
  companyDetails?: any;
  branchDetails?: any;
  roles?: string;
}

export enum UserUpdateCommand {
  NO_UPDATE = 0,
  CHECK_UPDATE = 1
}

export interface UserLogo {
  logoUrl: string;
  proportion: number;
}
