import { Injectable } from '@angular/core';
import { fromEvent, throwError } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';

import { ToastService } from '../../toast/toast.service';
import { environment } from '../../../environments/environment';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private readonly cookieService: CookieService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly languageService: LanguageService) {
  }

  static getLanguagePath() {
    return location.pathname.slice(1, 3);
  }

  static handleAppLanguage(language: string): boolean|string {
    if (!environment.production) {
      return false;
    }

    const languageCode = UtilService.getLanguagePath();
    const indexOf = environment.languageSettings.availableLanguages.indexOf(languageCode) >= 0;

    if (indexOf && language !== languageCode) {
      return UtilService.redirectionURI(language);
    }

    if (!indexOf && language !== 'de') {
      return `/${language}/index.html`;
    }

    return false;
  }

  static redirectTo(url: string) {
    location.replace(url);
  }

  static redirectionURI(language: string) {
    return (language === 'de') ? '/' : `/${language}/index.html`;
  }

  private isAnalyticsDisabledWithCookies() {
    return this.cookieService.get(environment.cookieNameAnalytics) === 'true';
  }

  navigateByUrlWithSkipLocationChangeEnabled(url) {
    this.router.navigateByUrl('/', { skipLocationChange: true })
    .then(() => this.router.navigateByUrl(url));
  }

  getWindowResizeAction() {
    return fromEvent(window, 'resize').pipe(debounceTime(200));
  }

  copyContentToClipBoard(content) {
    const createCopy = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', content);
      e.preventDefault();
    };
    document.addEventListener('copy', createCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', createCopy);
  }

  showToastWarning(error, delay = 0) {
    if (!error) {
      return;
    }
    const [e] = error?.errors;

    setTimeout(() => {
      this.toastService.show({
        text: e?.details,
        language: this.languageService.getCurrentLanguage(),
        type: 'warning'
      });
    }, delay);

    return throwError(e);
  }

  showToastInfo(info, delay = 0) {
    if (!info) {
      return;
    }

    setTimeout(() => {
      this.toastService.show({
        text: info?.details,
        type: 'info'
      });
    }, delay);
  }

  // Google Tag Manager
  appendGATrackingCode(trackingCode: string) {
    if (!trackingCode || this.isAnalyticsDisabledWithCookies()) {
      return;
    }
    try {
      const script = document.createElement('script');
      script.innerHTML =
        `
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
         {'gtm.start': new Date().getTime(),event:'gtm.js'}
         );var f=d.getElementsByTagName(s)[0],
         j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.nonce='a7b8c3d9e2f4a1b5c6d7e8f5a3b9c2';j.src=
         'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '` +
        trackingCode +
        `');
      `;
      script.setAttribute('nonce', '2f4e1a3c8d7b5e9f6c8a7b4d1e5f2c3');

      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  // Google Analytics
  appendGoogleAnalytics(gaCode: string) {
    if (!gaCode || this.isAnalyticsDisabledWithCookies()) {
      return;
    }

    const gaScript = document.createElement('script');
    gaScript.setAttribute('nonce', '3d9f7b4a6e5c8d2f1a0b7c6e8d9a5f4b');
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaCode}`;

    const gaScript2 = document.createElement('script');
    // tslint:disable-next-line:max-line-length
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ gaCode }\');`;
    gaScript2.setAttribute('nonce', 'b6d7e8a5c4f1b3d9a2c6e7d8b4a5f1c');

    document.head.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  // Google Tag Manager NoScript In Body
  appendGATrackingCodeInBody(trackingCode: string) {
    if (!trackingCode || this.isAnalyticsDisabledWithCookies()) {
      return;
    }

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${trackingCode}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.classList.add('visibility-hidden');
    iframe.classList.add('hidden');
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  }

}
