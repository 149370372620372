import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, publishLast, refCount } from 'rxjs/operators';

import { ErrorService } from './error.service';
import { UserLogo } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private cache = new Map();

  constructor(private readonly http: HttpClient, private  readonly errorService: ErrorService) {
  }

  get<T>(url: string, params?: any) {
    const options = {};
    if (params !== undefined) {
      for (const param of params) {
        options[param.name] = param.value;
      }
    }

    return this.http.get<T>(url)
      .pipe(
        catchError(e => this.errorService.handleError(e)),
        publishLast(),
        refCount());
  }

  getWithCache<T>(url: string, params?: any) {
    const exists = this.cache.get(url);

    if (exists) {
      return of(exists)
    }

    return this.get<T>(url, params).pipe(
      map(result => {
        this.cache.set(url, result);
        return result;
      })
    );
  }

  postWithId(url: string, data: any, params?: any) {
    return this.doPost(url, data, params);
  }

  post<T>(url: string, data: any, params?: any): Observable<any> {
    return this.doPost<T>(url, data, params);
  }

  putWithId<T>(url: string, data: any): Observable<T> {
    return this.doPut<T>(url, data);
  }

  private doPost<T>(url: string, data: any, params?: any) {

    return this.http.post(url, data)
      .pipe(
        map((res: Response) => res),
        catchError(e => this.errorService.handleError(e))
      );
  }

  delete(url: string, params?: any) {
    return this.http.delete(url);
  }

  private doPut<T>(url: string, data: any): Observable<T> {

    return this.http.put(url, data)
      .pipe(
        map((res: T) => res),
        catchError(e => this.errorService.handleError(e))
      );
  }

  public downloadPDF(url, data) {
    return this.http.post(url, data, {
      responseType: 'blob', headers: new HttpHeaders({
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token'))
      })
    });
  }

  public downloadCSV(url, data) {
    return this.http.post(url, data, {
      responseType: 'blob',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token'))
      })
    });
  }

  public updateContact(data, url: string) {
    return this.http.post(url, data, {
      responseType: 'blob',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token'))
      })
    });
  }

  postCompanyLogo(fileToUpload: File, url: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload, fileToUpload.name);
    return this.http.post(url, formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token'))
      })
    });
  }

  getUsersCompanyLogo(url: string): Observable<string> {
    return this.http.get(url,{
      responseType: 'text',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token'))
      })
    });
  }
}
