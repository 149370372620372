import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fallbackEnToDe'
})
export class FallbackEnToDePipe implements PipeTransform {

  transform(lang: string): string {
    if(!lang) {
      return 'de';
    }

    return lang === 'en' ? 'de' : lang;
  }

}
