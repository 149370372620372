<div class="se-loading" [style.minHeight.px]="minHeight">
  <div class="loading-icon">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1024" xml:space="preserve">
      <g id="icons">
        <g>
          <path d="M512,894c-51.6,0-101.6-10.1-148.7-30c-45.5-19.2-86.3-46.8-121.4-81.9c-35.1-35.1-62.6-75.9-81.9-121.4
            c-19.9-47.1-30-97.1-30-148.7s10.1-101.6,30-148.7c19.2-45.5,46.8-86.3,81.9-121.4s75.9-62.6,121.4-81.9
            c47.1-19.9,97.1-30,148.7-30v54c-87.6,0-170,34.1-231.9,96.1S184,424.4,184,512s34.1,170,96.1,231.9S424.4,840,512,840
            s170-34.1,231.9-96.1S840,599.6,840,512h54c0,51.6-10.1,101.6-30,148.7c-19.2,45.5-46.8,86.3-81.9,121.4
            c-35.1,35.1-75.9,62.6-121.4,81.9C613.6,883.9,563.6,894,512,894z"></path>
        </g>
      </g>
    </svg>
  </div>
</div>


