import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { UIModule } from './ui/ui.module';
import { ToastModule } from './toast/toast.module';
import { authZeroConfig, environment } from '../environments/environment';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    UiSwitchModule,
    HttpClientModule,
    UIModule,
    CookieModule.forRoot({
      domain: environment.cookieDomain,
    }),
    ContextMenuModule,
    /* @DEPRECATED  */
    /* We have to remove this */
    TranslateModule.forRoot(),
    ToastModule.forRoot(),
    AuthModule.forRoot({
      domain: authZeroConfig.domain,
      clientId: authZeroConfig.client_id,
      useRefreshTokensFallback: true,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: authZeroConfig.audience,
        connection: 'professional-users',
        scope: 'user_metadata company:details profile email'
      },
      useRefreshTokens: true,
      httpInterceptor: {
        allowedList: [
          environment.apiEndpoint + '/api',
          {
            uri: environment.apiEndpoint + '/api/*',
            allowAnonymous: false,
          }
        ],
      },
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


