import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CompanyAddressType } from '../../../../enclosure/models/documents-types.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-uploadlogo-personal-details',
  templateUrl: './uploadlogo-personal-details.component.html',
  styleUrls: ['./uploadlogo-personal-details.component.scss']
})
export class UploadlogoPersonalDetailsComponent {

  @Input() isContactChecked: boolean;
  @Input() isUpdateBtnLoading: boolean;
  @Input() personalDetailsForm: FormGroup;
  @Input() detailsLabel: string;
  @Input() addressOptionSelected: CompanyAddressType;

  @Output() toggleContactEmit = new EventEmitter();
  @Output() updateContactInformationEmit = new EventEmitter();
  @Output() focusOutPhone = new EventEmitter<string>();
  @Output() addressSelected = new EventEmitter<CompanyAddressType>();

  toggleContactInformation() {
    this.toggleContactEmit.emit();
  }

  updateContactInformation() {
    this.updateContactInformationEmit.emit();
  }

  regeneratePhoneNumber(phoneNumber: string) {
    this.focusOutPhone.emit(phoneNumber);
  }

  selectAddress(option: CompanyAddressType) {
    this.addressSelected.emit(option);
  }
}
