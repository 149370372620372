import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';

export class ToastRef {
  constructor(private readonly overlay: OverlayRef) { }

  close() {
    this.overlay.dispose();
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  getHeight() {
    return this.overlay.overlayElement?.getBoundingClientRect() || { height: 69 };
  }

  getOverlay() {
    return this.overlay;
  }

  getPosition() {
    return this.overlay.overlayElement?.getBoundingClientRect();
  }

  setPosition(positionBuilder: GlobalPositionStrategy) {
   this.overlay.updatePositionStrategy(positionBuilder);
   this.overlay.updatePosition();
  }
}
