import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { LanguageService } from '../../../shared/services/language.service';

@Component({
  selector: 'app-auth0-continue',
  templateUrl: './auth0-continue.component.html',
  styleUrls: ['./auth0-continue.component.scss']
})
export class Auth0ContinueComponent implements OnInit {

  constructor(private readonly authService: AuthService, private readonly languageService: LanguageService) { }

  ngOnInit(): void {
    this.authService.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`
      },
      // appState: { target: this.languageService.getLocalString() }
    });
  }
}
