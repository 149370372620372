export const gateway = {
  IPAddress: 'disposuite-dev-api.feller.ch',
  port: '',
  protocol: 'https',
};

export const authZeroConfig = {
  domain: 'id-staging.feller.ch',
  client_id: 'Wc6vSDeO4Axa7aaKwZM3vFZqyh9j0xRn',
  audience: 'https://disposuite-preprod.feller.ch'
};

// LOSI-51 Start
export const userProfileUrl = {
  domain: 'https://feller-account-staging.netlify.app'
};
// LOSI-51 End

export const productSelector = {
  productSelectorListing: gateway.protocol + '://' + gateway.IPAddress + '/api/v1/ProductSelector',
  productSelector: gateway.protocol + '://' + gateway.IPAddress + '/api/v1/ProductSelector/{psId}',
  productImage: gateway.protocol + '://' + gateway.IPAddress + '/api/v1/productCatalog/product/image/?reference={reference}'
};

export const environment = {
  cookieDomain: '.feller.ch',
  cookieNameAnalytics: 'google-analytics-opt-out',
  schemaVersion: 1,
  userFeedback: 'u6LVHtvZBfiHiIWdoCa8j25rpEeanGql',
  transferToElbridge2Link: 'https://interface.elbridge2.itek.de/',
  version: 'api/v1',
  production: true,
  apiEndpoint: gateway.protocol + '://' + gateway.IPAddress,
  ProductSelector: {
    productSelectorListing: productSelector.productSelectorListing,
    productSelector: productSelector.productSelector,
    productImage: productSelector.productImage
  },
  domain: 'https://disposuite-dev.feller.ch/#/home/shared',
  googleAnalyticsCode: 'UA-177090861-2',
  googleTrackingCode: 'GTM-W7XJRDK',
  languageSettings: {
    availableLanguages: [
      'de',
      'fr',
      'it',
      'en',
    ],
    defaultLanguage: 'de'
  },
};
