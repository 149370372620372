import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import Package from '../../../../../package.json';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() isOpened = false;
  @Input() selectedLanguage: string;

  version = Package.version;
  currentYear = new Date().getFullYear();

  toggleFooter() {
    this.isOpened = !this.isOpened;
  }
}
