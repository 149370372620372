import { IRectangle } from '../models/Rectangle.model';

export const widgetResizeThreshold = (gridDimensions: IRectangle, gridColumns: number, gridRows: number): IRectangle => {
  if (!gridDimensions || !gridColumns || !gridRows) {
    return {
      width: 0,
      height: 0
    }
  }
  const widthOfOne = gridDimensions.width / gridColumns;
  const heightOfOne = gridDimensions.height / gridRows;

  return {
    width: widthOfOne / 2,
    height: heightOfOne / 2
  }
}
