import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private applicationError$: Subject<number> = new Subject<number>();

  public getApplicationErrorState$() {
    return this.applicationError$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    );
  }

  public handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client side network error occurred:', error.error.message);
    } else {
      this.applicationError$.next(error.status);
    }

    // return an observable with a user-facing error message
    return throwError(error || 'server error');
  }
}
