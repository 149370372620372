import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  private invitation$ = new ReplaySubject<string>(0);
  private usedTokens = [];

  invitationSubscription() {
    return this.invitation$
      .asObservable()
      .pipe(
        filter(token => !this.usedTokens.includes(token))
      );
  }

  invitationReceived(token: string) {
    this.invitation$.next(token);
    setTimeout(() => {
      this.usedTokens.push(token);
    }, 500);
  }
}
