<ng-container [ngSwitch]="notificationText">
  <ng-container *ngSwitchCase="infoMessageTypes.DOWNLOAD_BEGIN" i18n="@@app.download_will_begin_shortly">Download will begin shortly!</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.CONFIGURATION_CONTAINS_EMPTY_SPACE" i18n="@@app.attention_the_configurated_enclosure_contains_empty_spaces">Attention: The configured enclosure contains empty spaces</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.CONFIGURATION_CONTAINS_MODULAR_KITS_AND_COMPLETE_FIELDS" i18n="@@app.complete_fields_and_modular_kits_were_mixed_in_the_configuration_please_check_segregations">Complete fields and modular kits were mixed in the configuration, please check segregations.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.ENCLOSURE_DOES_NOT_CONTAIN_ALL_COMPONENTS" i18n="@@app.cable_entering_plates_must_be_ordered_separately_for_each_field_on_the_top_and_on_the_bottom.">Cable entering plates must be ordered separately for each field on the top and on the bottom.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.BOM_COPIED_TO_CLIPBOARD" i18n="@@app.copied_to_clipboard">Copied to clipboard</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.PRODUCT_ADDED_TO_FAVORITE" i18n="@@app.product_added_to_favorites">Product added to favorites</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.PRODUCT_IS_NOT_AVAILABLE" i18n="@@app.product_is_not_available">The grayed out positions are not available anymore</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.LAST_SELECTED_APARTMENT" i18n="@@app.last_selected_apartment">At least one apartment need to be selected</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.LAST_SELECTED_ENCLOSURE" i18n="@@app.last_selected_enclosure">At least one enclosure need to be selected</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.FILE_UPLOAD" i18n="@@app.uploaded_document">Document uploaded</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.APP_HAS_PROBLEMS" i18n="@@app.app_is_facing_technical_issues_please_reload">App is facing temporary technical issues. Please, refresh your browser window.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.ADDITIONAL_POWER_SUPPLY" i18n="@@app.device_requires_an_additional_power_supply">Device requires an additional power supply.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.CHECK_COMPANY_ADDRESS" i18n="@@app.check_company_address">Please check your company address filled in the Profile of your Feller account.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.LIMIT_EXCEEDED">
    <ng-container i18n="@@app.file_size_exceeded">File size limit exceeded</ng-container>:
    <ng-container>
      {{ sizeLimitFile | formatBytes }}
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.COVER_IS_PERFORATED_AND_CAN_BE_BROKEN_OUT_MANUALLY" i18n="@@app.cover_is_perforated_and_can_be_broken_out_manually">Cover is perforated and can be broken-out manually.</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.FAILED_TO_DOWNLOAD_THE_DOCUMENT" i18n="@@app.failed_to_download_the_document">Failed to download the document</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.DOCUMENTS_ARE_BEING_DOWNLOADED" i18n="@@app.documents_are_being_downloaded">Documents are being downloaded</ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.LIMITED_AVAILABILITY_DEVICES_INFO">
    <ng-container i18n="@@app.attention">Attention</ng-container>!<br/>
    <ng-container i18n="@@app.limited_availability_of_the_required_comb">Limited availability of the required comb</ng-container>.
    <span class="ml-1"></span>
    <a [href]="language | combBusbarRecommendationLink" target="_blank">
      <span i18n="@@app.alternative_see_info" [ngClass]="{'text-white text-decoration-underline' : linkWhiteColor}">Alternative see info</span>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="infoMessageTypes.COMB_BUS_BAR_RECOMMENDATION_INFO">
    <ng-container i18n="@@app.recommendation">Recommendation</ng-container>!<br/>
    <span class="ml-1"></span>
    <a [href]="language | combBusbarRecommendationLink" target="_blank">
      <span i18n="@@app.alternative_see_info" [ngClass]="{'text-white text-decoration-underline' : linkWhiteColor}">Alternative see info</span>
    </a>
  </ng-container>
  <ng-container *ngSwitchDefault>{{ notificationText }}</ng-container>
</ng-container>
