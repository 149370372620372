import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';
import { DataService } from './data.service';
import Package from '../../../../package.json';
import { environment } from '../../../environments/environment';
import { User, UserUpdateCommand } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInformationData$ = new BehaviorSubject<User>(null);
  private userLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly configService: ConfigurationService,
    private readonly dataService: DataService) {
  }

  setUserInformation(user: User, notify = true) {
    this.userInformationData$.next(user);

    if (notify) {
      this.userLoaded$.next(true);
    }
  }

  getUserInformation() {
    return this.userInformationData$
      .pipe(
        filter(user => !!user)
      );
  }

  /**
   * This method should show that application is ready for use.
   */
  isUserLoaded() {
    return this.userLoaded$
      .pipe(
        filter(isLoaded => !!isLoaded)
      );
  }

  /**
   * Parameter e is used to give instructions to API to update user information with CIAM data.
   */
  getUserInfo(e = UserUpdateCommand.NO_UPDATE) {
    let url = environment.apiEndpoint + '/api/v1/user/info?includeCompanyLogo=true&e='+e;
    if (e === UserUpdateCommand.CHECK_UPDATE) {
      url += '&v=' + Package.version;
    }

    return this.dataService.get(url);
  }

  shouldDisplayVersionUpdate(user: User) {
    return user.showUpdate === 'True';
  }

  updateUserLanguage(lang) {
    const url = this.configService.getUrl('user', 'lang');

    return this.getUserInformation().pipe(switchMap(user => {
      return this.dataService.post(url, {
        ...user,
        lang
      });
    }));
  }

  cleanLocalStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('exp');
  }
}
