import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ConfigurationService } from '../../../shared/services/configuration.service';
import { DataService } from '../../../shared/services/data.service';
import { environment } from '../../../../environments/environment';
import { PHONE_REGEX } from '../../../shared/constants/phone-regex.constants';
import { PhoneService } from '../../../shared/services/phone.service';
import { CompanyAddressType, DocumentLogoErrorType, DocumentLogoType } from '../../../enclosure/models/documents-types.constants';
import { ICompanyAddress } from '../../../enclosure/models/document-settings.model';
import { UserService } from '../../../shared/services/user.service';
import { User } from '../../../shared/models/user';
import { getCompanyAddress } from '../../helpers/documents.helper';
import { UsersLogoService } from '../../../shared/services/users-logo.service';

declare var jQuery: any;

@Component({
  selector: 'app-uploadlogo',
  templateUrl: './uploadlogo.component.html',
  styleUrls: ['./uploadlogo.component.scss']
})
export class UploadlogoComponent implements OnInit {

  @ViewChild('settingsModal') settingsModalEl: ElementRef;

  @Input() logoLabel: string;
  @Input() detailsLabel: string;

  defaultLogoPath: string;
  errorMessage: string;
  logoOptionSelected: number;
  isContactChecked: boolean;
  fileToUpload: File;
  isLoading: boolean;
  isUpdateBtnLoading = false;
  personalDetailsForm: FormGroup;
  savedLogoInDB: string;
  logoFromCIAM: string;
  companyAddress: ICompanyAddress;
  branchAddress: ICompanyAddress;
  addressOptionSelected: CompanyAddressType;
  imageProportion: number;

  constructor(private readonly configService: ConfigurationService,
              private readonly auth: UserService,
              private readonly phoneService: PhoneService,
              private readonly dataService: DataService,
              private readonly cd: ChangeDetectorRef,
              private readonly usersLogoService: UsersLogoService) {
  }

  private static setCompanyName(form: FormGroup, name: string) {
    form.patchValue({
      companyName: name
    });
  }

  private static setCompanyAddressInForm(form: FormGroup, address: ICompanyAddress) {
    const zipCity = (address.zip ? address.zip + ' ' : '') + (address.city ? address.city : '');
    form.patchValue({
      companyZipCity: zipCity,
      companyStreet: address.street
    });
  }

  ngOnInit() {
    this.componentFactory();
    this.initFormGroup(true);
  }

  private componentFactory() {
    this.defaultLogoPath = '';
    this.isContactChecked = true;
    this.fileToUpload = null;
    this.isLoading = false;
    this.isUpdateBtnLoading = false;
    this.logoFromCIAM = null;
  }

  initFormGroup(isDisabled) {
    // tslint:disable-next-line: max-line-length
    const regEmail = /^(?!.*\.{2})([a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df!#$%&'*+\/=?^_`{|}~-]+([\.][a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df!#$%&'*+\/=?^_`{|}~-]+)*)@((([\-]?[a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df]){2,}[\.])*(([a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df][\-]?))+).(([\.]([a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df][\-]?){2,}([a-zA-Z0-9\u00c4-\u00e4\u00d6-\u00f6\u00dc-\u00fc\u00df])*)+)$/;
    this.personalDetailsForm = new FormGroup({
      firstName: new FormControl({value: '', disabled: isDisabled},
        [ Validators.required
        ]),
      lastName: new FormControl({value: '', disabled: isDisabled},
        [ Validators.required
        ]),
      phoneNumber: new FormControl({value: '', disabled: isDisabled},
        [ Validators.required,
          Validators.pattern(PHONE_REGEX)
        ]),
      email: new FormControl({value: '', disabled: isDisabled},
        [
          Validators.required,
          Validators.maxLength(255),
          Validators.pattern(regEmail)
        ]),
      image: new FormControl(this.usersLogoService.getUsersLogo()),
      companyName: new FormControl({value: '', disabled: true}),
      companyStreet: new FormControl({value: '', disabled: true}),
      companyZipCity: new FormControl({value: '', disabled: true})
    });
  }

  updateDetailsWithDataFromDispoDB(data) {
    if (data.useSecondaryDetails) {
      this.updateUserDetails(
        this.personalDetailsForm,
        data.secondaryFirstName,
        data.secondaryLastName,
        data.secondaryMobileNumber,
        data.secondaryEmail);
    }
  }

  updateUserDetails(form: FormGroup, fname: string, lname: string, phone: string, mail: string) {
    form.patchValue({
      firstName: fname,
      lastName: lname,
      phoneNumber: phone,
      email: mail
    });
  }

  selectCompanyAddress(option: number) {
    switch(option) {
      case CompanyAddressType.MAIN_COMPANY:   UploadlogoComponent.setCompanyAddressInForm(this.personalDetailsForm, this.companyAddress)
                                              break;

      case CompanyAddressType.BRANCH_COMPANY: UploadlogoComponent.setCompanyAddressInForm(this.personalDetailsForm, this.branchAddress)
                                              break;
    }
    this.addressOptionSelected = option;
  }

  onImageSelect(file: FileList) {
    if (file === undefined || file?.length === 0) {
      return;
    }

    const fileType = file[0].type;
    const fileSize = file[0].size / 1048576; // file[0].size is in bytes. So no. of bytes/ (1024*1024)mb
    if (fileType.toLowerCase() === 'image/jpeg' || fileType.toLowerCase() === 'image/png') {
      if (fileSize > 3) {
        this.errorMessage = DocumentLogoErrorType.SIZE;
      } else {
        this.errorMessage = null;
        this.fileToUpload = file.item(0);
        this.updateCompanyLogo(file.item(0));
      }

    } else {
      this.errorMessage = DocumentLogoErrorType.TYPE;
    }
  }

  clearImage() {
    this.fileToUpload = null;
    this.usersLogoService.clearUsersLogo();
    this.logoOptionSelected = DocumentLogoType.DISPOSUITE_LOGO;
    this.imageProportion = null;
    this.savedLogoInDB = null;
    this.cd.markForCheck();
    this.updateContactInformation(DocumentLogoType.DISPOSUITE_LOGO);
  }

  updateCompanyLogo(logoFileToUpload: File) {
    this.logoOptionSelected = DocumentLogoType.CUSTOM;
    this.usersLogoService.uploadLogo(logoFileToUpload).subscribe(
      _ => this.updateContactInformation(DocumentLogoType.CUSTOM),
      error => {
        this.logoOptionSelected = DocumentLogoType.DISPOSUITE_LOGO;

        const [message] = error?.error?.errors;
        if (message?.details === 'wrong_dimensions') {
          this.errorMessage = DocumentLogoErrorType.DIMENSIONS;
        }
      })
  }

  updateContactInformation(logoOptionSelected: DocumentLogoType) {
    const data: any = {
      useCompanyLogo: logoOptionSelected,
      secondaryFirstName: this.personalDetailsForm.value.firstName,
      secondaryLastName: this.personalDetailsForm.value.lastName,
      secondaryMobileNumber: this.personalDetailsForm.value.phoneNumber,
      secondaryEmail: this.personalDetailsForm.value.email,
      useSecondaryDetails: !this.isContactChecked,
      companyAddressType: this.addressOptionSelected
    };

    const baseUrl = environment.apiEndpoint;
    const url: string = baseUrl + '/api/v1/user/info';
    this.dataService.updateContact(data, url).subscribe();
  }

  saveContactInformation() {
    this.updateContactInformation(this.logoOptionSelected);

    this.imageProportion = null;
    this.closeModal();
  }

  toggleContactInformation() {
    this.isContactChecked = !this.isContactChecked;
    this.auth.getUserInfo().subscribe((user: User) => {
      this.updateUserDetails(this.personalDetailsForm, user.firstName, user.lastName,
        user.phone, user.email);
      this.toggleInputDisability(this.isContactChecked);
    });
  }

  toggleInputDisability(isDisabled) {
    // tslint:disable-next-line: no-unused-expression
    this.personalDetailsForm.get('firstName')[isDisabled ? 'disable' : 'enable']();
    this.personalDetailsForm.get('lastName')[isDisabled ? 'disable' : 'enable']();
    this.personalDetailsForm.get('phoneNumber')[isDisabled ? 'disable' : 'enable']();
    this.personalDetailsForm.get('email')[isDisabled ? 'disable' : 'enable']();
  }

  regeneratePhoneNumber(phoneNumber) {
    const updatedPhoneNumber = this.phoneService.generatePhoneNumber(phoneNumber, this.personalDetailsForm.get('phoneNumber').valid);
    this.personalDetailsForm.get('phoneNumber').setValue(updatedPhoneNumber);
  }

  openModal() {
    this.initBeforeShowModal();
    this.initFormGroup(true);
    jQuery(this.settingsModalEl.nativeElement).modal('show');
  }

  closeModal() {
    jQuery(this.settingsModalEl.nativeElement).modal('hide');
  }

  initBeforeShowModal() {
    this.auth.getUserInfo().subscribe((user: User) => {
      this.errorMessage = null;
      this.isContactChecked = !user.useSecondaryDetails;
      this.logoOptionSelected = user.useCompanyLogo;
      this.logoFromCIAM = user.companyDetails?.logoUrl ? user.companyDetails?.logoUrl : null;
      this.branchAddress = getCompanyAddress(user.branchDetails?.branchStreet,
        user.branchDetails?.branchCity,
        user.branchDetails?.branchZip);
      this.companyAddress = getCompanyAddress(user.companyDetails?.street,
        user.companyDetails?.city,
        user.companyDetails?.zip);
      this.selectCompanyAddress(user.companyAddressType);
      UploadlogoComponent.setCompanyName(this.personalDetailsForm, user.companyDetails?.name);
      this.usersLogoService.updateUsersLogoBasedOnOptionSelected(user);

      if (this.isContactChecked) {
        this.updateUserDetails(
          this.personalDetailsForm,
          user.firstName,
          user.lastName,
          user.phone,
          user.email);
      } else {
        this.updateDetailsWithDataFromDispoDB(user);
      }

      this.toggleInputDisability(this.isContactChecked);
      this.cd.markForCheck();
    });
  }

}
