import { NgModule, ModuleWithProviders } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

import { ToastComponent } from './components/toast.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { NotificationInfoComponent } from './components/notification-info/notification-info.component';
import { CombBusbarRecommendationLinkPipe } from './pipes/comb-busbar-recommendation-link.pipe';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
    ],
    declarations: [
        ToastComponent,
        FormatBytesPipe,
        NotificationInfoComponent,
        CombBusbarRecommendationLinkPipe
    ],
    exports: [
        NotificationInfoComponent
    ]
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
