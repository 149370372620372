<p class="font-size-16 text-color-brand font-weight-semibold">
  <ng-container i18n="@@app.contact_information">Contact Information</ng-container>
</p>
<form [formGroup]="personalDetailsForm">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div class="checkbox">
        <input type="checkbox" name="isContactChecked" [(ngModel)]="isContactChecked" id="{{detailsLabel}}"
          [ngModelOptions]="{standalone: true}" (change)="toggleContactInformation()">
        <label class="position-relative cursor-pointer font-weight-semibold color-black font-weight-bold" for="{{detailsLabel}}">
          <ng-container i18n="@@app.fetch_data_automatically">Take First Name, Last Name and Phone Number from Profile directly</ng-container>
        </label>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.first_name_logo">First Name</ng-container>
      </label>
      <input type="text" class="form-control" name="firstName" formControlName="firstName"
      [ngClass]="{'is-invalid': personalDetailsForm.get('firstName').invalid}" required>
      <div *ngIf="personalDetailsForm.get('firstName').errors && (personalDetailsForm.get('firstName').dirty || personalDetailsForm.get('firstName').touched)"
        class="invalid-feedback">
        <span *ngIf="personalDetailsForm.get('firstName').errors.pattern || personalDetailsForm.get('firstName').errors.minlength">
          <ng-container i18n="@@app.firstname_error">First Name allows only Alphabet</ng-container>
        </span>
        <span *ngIf="personalDetailsForm.get('firstName').errors.required">
          <ng-container i18n="@@app.mandatory_field">Mandatory field</ng-container>
        </span>
      </div>
    </div>


    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.last_name_logo">Last Name</ng-container>
      </label>
      <input type="text" class="form-control" name="lastName" formControlName="lastName"
        [ngClass]="{'is-invalid': personalDetailsForm.get('lastName').invalid}" required>
      <div *ngIf="personalDetailsForm.get('lastName').errors && (personalDetailsForm.get('lastName').dirty || personalDetailsForm.get('lastName').touched)"
        class="invalid-feedback">
        <span *ngIf="personalDetailsForm.get('lastName').errors.pattern || personalDetailsForm.get('lastName').errors.minlength">
          <ng-container i18n="@@app.lastname_error">Last Name allows only Alphabet</ng-container>
        </span>
        <span *ngIf="personalDetailsForm.get('lastName').errors.required">
          <ng-container i18n="@@app.mandatory_field">Mandatory field</ng-container>
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.email">Email</ng-container>
      </label>
      <input type="text" class="form-control" name="email" formControlName="email"
        [ngClass]="{'is-invalid': personalDetailsForm.get('email').invalid}" required>
      <div *ngIf="personalDetailsForm.get('email').errors && (personalDetailsForm.get('email').dirty || personalDetailsForm.get('email').touched)"
        class="invalid-feedback">
        <span *ngIf="personalDetailsForm.get('email').errors.pattern || personalDetailsForm.get('email').errors.minlength">
          <ng-container i18n="@@app.email_error">Invalid email format</ng-container>
        </span>
        <span *ngIf="personalDetailsForm.get('email').errors.required">
          <ng-container i18n="@@app.mandatory_field">Mandatory field</ng-container>
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.mobile_phone_logo">Mobile phone number</ng-container>
      </label>
      <input type="text" class="form-control" name="phonenumber" formControlName="phoneNumber"
             (focusout)="regeneratePhoneNumber(personalDetailsForm.value.phoneNumber)"
      [ngClass]="{'is-invalid': personalDetailsForm.get('phoneNumber').invalid}" required>
      <div *ngIf="personalDetailsForm.get('phoneNumber').errors && (personalDetailsForm.get('phoneNumber').dirty || personalDetailsForm.get('phoneNumber').touched)"
        class="invalid-feedback">
        <span *ngIf="personalDetailsForm.get('phoneNumber').errors.pattern || personalDetailsForm.get('phoneNumber').errors.minlength">
          <ng-container i18n="@@app.phonenumber_error">Phone number allows only number or + and should be of 13 characters</ng-container>
        </span>
        <span *ngIf="personalDetailsForm.get('phoneNumber').errors.required">
          <ng-container i18n="@@app.mandatory_field">Mandatory field</ng-container>
        </span>
      </div>
    </div>
  </div>
  <div class="row py-2">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-inline-flex font-weight-semibold text-color-medium-gray">
      <div class="d-flex mr-2 cursor-pointer" (click)="selectAddress(0)">
        <ng-container *ngIf="addressOptionSelected === 0 else uncheckedRadio">
          <img class="pr-2" src="./assets/images/radio_chacked_icon.svg">
        </ng-container>
        <span class="pr-1 py-1 text-uppercase" [ngClass]="{ 'color-black font-weight-bold': addressOptionSelected === 0}">
          <ng-container i18n="@@app.company_address">Company Address</ng-container>
        </span>
      </div>
      <div class="d-flex mr-2 cursor-pointer" (click)="selectAddress(1)">
        <ng-container *ngIf="addressOptionSelected === 1 else uncheckedRadio">
          <img class="pr-2" src="./assets/images/radio_chacked_icon.svg">
        </ng-container>
        <span class="pr-1 py-1 text-uppercase" [ngClass]="{ 'color-black font-weight-bold': addressOptionSelected === 1}">
          <ng-container i18n="@@app.branch_address">Branch Address</ng-container>
        </span>
      </div>
    </div>
  </div>
  <ng-template #uncheckedRadio>
    <img class="pr-2" src="./assets/images/radio_empty_icon.svg">
  </ng-template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.company_name_title">Company Name</ng-container>
      </label>
      <input type="text" class="form-control" name="companyName" formControlName="companyName">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.street">Street</ng-container>
      </label>
      <input type="text" class="form-control" name="companyStreet" formControlName="companyStreet">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 form-group">
      <label class="mb-1 mt-1 text-color-medium-gray">
        <ng-container i18n="@@app.company_zip_city">ZIP and City</ng-container>
      </label>
      <input type="text" class="form-control" name="companyZipCity" formControlName="companyZipCity">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 modal-footer border-0">
      <button type="button" class="btn btn-light btn-lg text-uppercase border-rounded font-weight-normal"
              data-dismiss="modal">
        <ng-container i18n="@@app.cancel">Cancel</ng-container>
      </button>
      <button *ngIf="!isUpdateBtnLoading" appDisableMultipleClick [disabled]="personalDetailsForm.invalid" type="submit" class="btn btn-lg btn-primary bg-light-blue text-uppercase border-rounded font-weight-normal float-right"
        (click)="updateContactInformation()">
        <ng-container i18n="@@app.update_button_logo">Update</ng-container>
      </button>
      <button *ngIf="isUpdateBtnLoading" type="button" disabled class="btn btn-primary btn-lg float-right upload-details-button">
        <div class="spinner-border" role="status">
          <span class="sr-only">
            <ng-container i18n="@@app.loading_logo">Loading...</ng-container>
          </span>
        </div>
      </button>
    </div>
  </div>
</form>
