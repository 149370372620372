<div class="disposuite-header">
  <div class="container-fluid window-max-width px-4">
    <div class="row">
      <div class="col-6">
        <div class="mt-2 d-flex align-items-center h-40">
          <a routerLink="/" class="mt-auto mb-auto">
            <img src="{{imagePath}}DispoSuite_logo@3x.svg" alt="DispoSuite" width="145px" height="26px"/>
          </a>
        </div>
      </div>
      <div class="col-6">
        <div class="justify-content-end align-items-center mt-2 d-flex">
          <div class="mt-2 hidden-xs">
            <ng-container *ngIf="user?.picture; else userIcon">
              <img src="{{user?.picture}}" [title]="user?.name" width="30px" class="rounded-circle"/>
            </ng-container>
            <ng-template #userIcon>
              <span class="icon28 se-icon-user_standard"></span>
            </ng-template>
          </div>
          <div *ngIf="user" class="mt-1 px-0 btn-group" role="group">
            <div class="dropdown">
              <button class="btn dropdown-toggle dropdown-btn font-weight-bold" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ user?.name }}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" [href]="userProfileUrl" target="_blank" rel="opener"
                   *ngIf="isLoggedIn" i18n="@@app.my_profile">My Profile</a>
                <a class="dropdown-item cursor-pointer" (click)="onOpenSettings()"
                   *ngIf="isLoggedIn" i18n="@@app.settings">Settings</a>
                <a class="dropdown-item" href="#" (click)="onLogin()" *ngIf="!isLoggedIn" i18n="@@app.login">Login</a>
                <a class="dropdown-item" href="#" (click)="onLogout()" *ngIf="isLoggedIn" i18n="@@app.logout">Logout</a>
              </div>
            </div>
          </div>
          <div class="float-right mt-1">
            <select class="DropDown2 DropDown float-right align-text-top round-button" (change)="onChangeLanguage(selectedLanguage)"
                    [(ngModel)]="selectedLanguage">
              <option *ngFor="let locale of availableLanguages" value="{{locale}}">{{locale}}</option>
            </select>
          </div>
          <img alt="Schneider" class="float-right mt-2 ml-3 hidden-sm" width="95px" src="{{imagePath}}schneider.svg"/>
        </div>
      </div>
    </div>
  </div>
</div>
