import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { INotification } from '../models/notification.model';
import { uuidv4 } from '../helpers/grid-devices.helper';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsDic = {};
  private notifications$ = new BehaviorSubject<object>(this.notificationsDic);

  getNotifications$(): Observable<object> {
    return this.notifications$.asObservable();
  }

  addToNotifications(notification: Partial<INotification>, enclosureID: number) {
    if (enclosureID in this.notificationsDic) {
      const messageWithSameInfoIndex = this.notificationsDic[enclosureID]?.findIndex(n => n.messageInfo === notification.messageInfo);
      if (messageWithSameInfoIndex !== -1) {
        // if the message is already in the notifications array, remove it from there and add it on the top
        this.notificationsDic[enclosureID].splice(messageWithSameInfoIndex, 1);
      }
      const messageWithSameInfoUUID = this.notificationsDic[enclosureID][messageWithSameInfoIndex]?.notificationUUID;
      this.notificationsDic[enclosureID].unshift({ ...notification, notificationUUID: messageWithSameInfoUUID || uuidv4() });
    } else {
      this.notificationsDic[enclosureID] = [{ ...notification, notificationUUID: uuidv4() }];
    }

    this.notifications$.next(this.notificationsDic);
  }

  removeFromNotifications(notificationUUID: string, enclosureID: number) {
    const notificationIndex = this.notificationsDic[enclosureID]?.findIndex(notification =>
      notification.notificationUUID === notificationUUID);
    if (notificationIndex === -1) {
      // notification not found
      return;
    }

    this.notificationsDic[enclosureID]?.splice(notificationIndex, 1);
    this.notifications$.next(this.notificationsDic);
  }

  clearAll(enclosureID: number) {
    this.notificationsDic[enclosureID] = [];
    this.notifications$.next(this.notificationsDic);
  }

}
