import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly defaultLanguage = environment.languageSettings.defaultLanguage;

  constructor(private readonly translateService: TranslateService, @Inject(LOCALE_ID) public locale: string) {}

  setCustomLanguage(lang) {
    if (lang === undefined) {
      lang = this.defaultLanguage;
    }

    localStorage.setItem('lang', lang);
    this.setLanguage(lang);
  }

  getCustomLanguage() {
    return localStorage.getItem('lang');
  }

  configureLanguageSettings() {
    this.translateService.addLangs(environment.languageSettings.availableLanguages);

    const customLanguage = this.getCustomLanguage();
    if (customLanguage == null) {
      this.setBrowserLanguageAsDefault();
    } else {
      this.setDefaults(customLanguage);
      this.setLanguage(customLanguage);
    }
  }

  private setDefaults(lang) {
    this.translateService.setDefaultLang(lang);
  }

  private getBrowserLanguage() {
    const lang = this.translateService.getBrowserLang();
    if (environment.languageSettings.availableLanguages.indexOf(lang) > -1) {
      return lang;
    } else {
      return this.defaultLanguage;
    }
  }

  private setLanguage(lang) {
    this.translateService.use(lang);
    this.translateService.onTranslationChange.emit();
  }

  private setBrowserLanguageAsDefault() {
    let lang = this.defaultLanguage;
    if (environment.languageSettings.availableLanguages.indexOf(this.getBrowserLanguage()) > -1) {
      lang = this.getBrowserLanguage();
    }

    this.setDefaults(lang);
    this.setCustomLanguage(lang);
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  getLocalString() {
    if (this.locale === 'en-US') {
      return '/en';
    }

    return '/' + this.locale;
  }
}
