import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { DocumentLogoErrorType, DocumentLogoType } from '../../../../enclosure/models/documents-types.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-uploadlogo-image',
  templateUrl: './uploadlogo-image.component.html',
  styleUrls: ['./uploadlogo-image.component.scss']
})
export class UploadlogoImageComponent {

  documentLogoType = DocumentLogoType;
  documentLogoErrorType = DocumentLogoErrorType;

  @Input() logoOptionSelected: number;
  @Input() errorMessage: string;
  @Input() isLoading: boolean;
  @Input() image: string;
  @Input() logoLabel: string;
  @Input() imageProportion: number;

  @Output() imageSelectedEmit = new EventEmitter<FileList>();
  @Output() clearImageEmit = new EventEmitter();

  onImageSelect(event) {
    this.imageSelectedEmit.emit(event);
  }

  clearImage() {
    this.clearImageEmit.emit();
  }

}
