export enum DocumentsTypes {
  BOM_WITH_PRICE = 0,
  BOM_WITHOUT_PRICE = 1,
  ENCLOSURE_LAYOUT = 2,
  ENCLOSURE_LAYOUT_WITH_COVERS = 3,
  DISPOSITION_LAYOUT = 4,
  DISPOSITION_LAYOUT_WITH_COVERS = 5,
  DISPOSITION_LABELS = 6,
  DISPOSITION_LABELS_BACKSIDE = 7,
  RATING_PLATE = 8,
  APARTMENT_WITH_LEGEND = 9,
  SLD = 10,
  ROUTINE_VERIFICATION = 11
}

export enum DocumentLogoType {
  YOUR_COMPANY = 2,
  CUSTOM = 1,
  DISPOSUITE_LOGO  = 0
}

export enum CompanyAddressType {
  MAIN_COMPANY = 0,
  BRANCH_COMPANY = 1
}

export enum DocumentLogoErrorType {
  SIZE = 'size',
  TYPE = 'type',
  DIMENSIONS = 'dimensions'
}
