import { Component, Input } from '@angular/core';

import { ToastInfoEnum } from '../../toast.info.enum';
import edesignconstant from '../../../../assets/edesignconfig.json';

@Component({
  selector: 'app-notification-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.scss']
})
export class NotificationInfoComponent {
  infoMessageTypes = ToastInfoEnum;
  sizeLimitFile = edesignconstant.AssosciatedFileLimit;

  @Input() notificationText: string;
  @Input() language: string;
  @Input() linkWhiteColor = false;
}
