import { ElementRef } from '@angular/core';

export const elementIsShown = (element: ElementRef): boolean => {
  return element?.nativeElement?.classList?.contains('show');
}

export const createHiddenInput = (name: string, value: string): HTMLInputElement => {
  const input: HTMLInputElement = document.createElement('input');
  input.type = 'hidden';
  input.name = name;
  input.value = value;

  return input;
}
