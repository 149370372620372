import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridOverlayComponent } from './components/gridOverlay/gridOverlay.component';
import { WidgetComponent } from './components/widget/widget.component';
import { WidgetGridComponent } from './components/grid/grid.component';
import { WidgetMoverDirective } from './directives/widgetMover.directive';
import { WidgetResizerDirective } from './directives/widgetResizer.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    GridOverlayComponent,
    WidgetComponent,
    WidgetGridComponent,
    WidgetMoverDirective,
    WidgetResizerDirective
  ],
  exports: [
    WidgetComponent,
    WidgetGridComponent
  ]
})
export class WidgetGridModule {
}
