import { IChangeWidgetPosition, IGridPosition, IWidget } from '../models/prisma-xs.model';
import { Rectangle } from '../../widget-grid/models/Rectangle.model';
import { IArticle, IRules } from '../models/article.model';
import { PrismaXsConstant } from '../../shared/models/prisma-xs.constant';
import { PragmaConstant } from '../../enclosure/models/pragma.constant';
import { preloadImage } from '../../shared/helpers/preloadImage.helper';
import { KitType } from '../../shared/models/prisma-xs.model';
import { ProductStatus } from '../../shared/models/product.model';

export function cloneObject(obj) {
  if (obj === null || typeof (obj) !== 'object') {
    return obj;
  }
  const temp = obj.constructor(); // changed
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      temp[key] = cloneObject(obj[key]);
    }
  }
  return temp;
}

export function shallowCloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export const generate2DImageUrl = (image: string, type: 'support'|'cover') => {
  return `${PrismaXsConstant.KITS_IMAGE_LOCATION}/2d/${image}_${type}.${PragmaConstant.KITS_IMAGE_TYPE}`;
};

export const generateImageUrl = (image: string) => {
  return `${PrismaXsConstant.KITS_IMAGE_LOCATION}/${image}.${PrismaXsConstant.KITS_IMAGE_TYPE}`;
};

export const extractArticleWithPosition = (position: Rectangle, articles: IArticle[], productType: string) => {
  return articles.find(item => {
    return item.widget.width === position.width && item.widget.height === position.height && item.productType === productType
      && !item?.rules?.kit?.noResize && item?.status === ProductStatus.ACTIVE;
  });
};

export const checkIfArticlesAreIdentical = (first: IWidget, second: IWidget) => {
  return first?.height === second?.height && first?.width === second?.width;
};

export const generateGridPositions = (gridRectangle: Rectangle , gridRows, gridColumns): IGridPosition[] => {
  const positionsByGrid = [];

  const cellWidth = gridRectangle.width / gridColumns;
  const cellHeight = gridRectangle.height / gridRows;
  let index = 0;
  for (let i = 0; i < gridRows; i++) {
    for (let j = 0; j < gridColumns; j++, index++) {
      const position = {
        id: index,
        size: {
          top: i + 1,
          left: j + 1
        },
        centerCoordinates: {
          x: gridRectangle.left + (j + 1) * cellWidth - cellWidth / 2,
          y: gridRectangle.top + (i + 1) * cellHeight - cellHeight / 2
        }
      };
      positionsByGrid.push(position);
    }
  }

  return positionsByGrid;
};

export const distance = (p, point) => {
  return Math.sqrt(Math.pow(point.x - p.x, 2) + Math.pow(point.y - p.y, 2));
};

export const hasOwnUprights = (kit: IArticle): boolean => {
  return kit.rules.upright.noUprights;
};

export const generateUprightReference = (rows: number, vertical = true) => {
  let referenceRows = '0' + rows;
  if (rows > 9) {
    referenceRows = rows?.toString();
  }

  if (!vertical) {
    return 'SZFVW{n}6N.png'.replace('{n}', rows.toString());
  }

  return 'SZMNT' + referenceRows + 'N2.png';
};

export const preloadUprightImages = () => {
  const rows = [2, 3, 4, 5, 6, 7, 8, 9, 12];

  rows.forEach(row => {
    preloadImage('/assets/prisma-xs/upright-background/' + generateUprightReference(row));
  });
};

export const productIsCompleteMultimediaFieldOrMediaKit = (productRef: string) => {
  return PrismaXsConstant.CANT_BE_PLACED_IN_280_DEEP_ENCLOSURE
    .findIndex(unavailableRef => productRef === unavailableRef) !== -1;
};

export const productIsCompleteField = (kitType: KitType): boolean => {
  return kitType === KitType.CompleteFields;
};

export const overwritingRules = (reference: string, rule: IRules) => {
  if (PrismaXsConstant.OVERWRITE_CAN_GO_TROUGH_RULE.includes(reference)) {
    rule.upright.allowGoTrough = true;
  }

  return rule;
}

export const stripDownWidgetObject = (widget: IChangeWidgetPosition) => {
  return {
    index: widget.index,
    widget: {
      top: widget.widget.top,
      left: widget.widget.left,
      width: widget.widget.width,
      height: widget.widget.height,
    }
  }
}
