<div class="wg-widget-content" [ngClass]="{'resize-outside-height': resizeOutside && isMaxRows, 'resize-outside-width': resizeOutside && isMaxFields}">
  <ng-content></ng-content>
</div>
<div [ngxWidgetMover]="swapOnMove"
     *ngIf="movable"
     class="wg-widget-edit-move cursor-grab">
</div>
<div *ngIf="selected"
  class="wg-widget-borders">
  <div class="wg-resize wg-resize-axis wg-border-n" [ngClass]="{'outside': outsideBorder}" ></div>
  <div class="wg-resize wg-resize-axis wg-border-e" [ngClass]="{'outside': outsideBorder}"></div>
  <div class="wg-resize wg-resize-axis wg-border-s" [ngClass]="{'outside': outsideBorder}"></div>
  <div class="wg-resize wg-resize-axis wg-border-w" [ngClass]="{'outside': outsideBorder}"></div>
</div>
<div *ngIf="resizable" class="wg-widget-edit-resize">
  <div class="wg-resize wg-resize-axis wg-resize-n"
       [ngxWidgetResizer]="allDirections.top"
       [hidden]="!isTopResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div [ngClass]="{'outside': outsideBorder && !isMaxFields}" class="wg-resize wg-resize-axis wg-resize-e"
       [ngxWidgetResizer]="allDirections.right"
       [hidden]="!isRightResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div [ngClass]="{'outside': outsideBorder && !isMaxRows}" class="wg-resize wg-resize-axis wg-resize-s"
       [ngxWidgetResizer]="allDirections.bottom"
       [hidden]="!isBottomResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div class="wg-resize wg-resize-axis wg-resize-w"
       [ngxWidgetResizer]="allDirections.left"
       [hidden]="!isLeftResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div class="wg-resize wg-resize-diag wg-resize-nw"
       [ngxWidgetResizer]="allDirections.topLeft"
       [hidden]="!isTopLeftResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div class="wg-resize wg-resize-diag wg-resize-ne"
       [ngxWidgetResizer]="allDirections.topRight"
       [hidden]="!isTopRightResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div [ngClass]="{'outside': outsideBorder && (!isMaxFields || !isMaxRows)}" class="wg-resize wg-resize-diag wg-resize-se"
       [ngxWidgetResizer]="allDirections.bottomRight"
       [hidden]="!isBottomRightResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
  <div class="wg-resize wg-resize-diag wg-resize-sw"
       [ngxWidgetResizer]="allDirections.bottomLeft"
       [hidden]="!isBottomLeftResizable"
       [minColumns]="minColumns"
       [minRows]="minRows"
       [maxColumns]="maxColumns"
       [maxRows]="maxRows"></div>
</div>

