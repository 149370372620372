import { Injectable } from '@angular/core';

import _configSettings from './../../../assets/edesignconfig.json';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private readonly config: { [index: string]: any };
  public imagePath = '/assets/images/';
  constructor() {
    this.config = _configSettings;
  }

  getConfiguration() {
    return this.config;
  }

  getUrlsObject() {
    return this.config.urlSettings;
  }

  getProductSelectorLink() {
    return environment.ProductSelector.productSelectorListing;
  }

  getProductImageUrl() {
    return environment.ProductSelector.productImage;
  }

  public getUrl(...args) {
    let baseUrl = environment.apiEndpoint + '/' + environment.version;

    for (const argument of arguments) {
      baseUrl += '/' + argument;
    }

    return baseUrl;
  }
}
