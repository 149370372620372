export enum ToastInfoEnum {
  DOWNLOAD_BEGIN = 'DOWNLOAD_BEGIN',
  DOCUMENTS_ARE_BEING_DOWNLOADED = 'DOCUMENTS_ARE_BEING_DOWNLOADED',
  CONFIGURATION_CONTAINS_EMPTY_SPACE = 'CONFIGURATION_CONTAINS_EMPTY_SPACE',
  CONFIGURATION_CONTAINS_MODULAR_KITS_AND_COMPLETE_FIELDS = 'CONFIGURATION_CONTAINS_MODULAR_KITS_AND_COMPLETE_FIELDS',
  ENCLOSURE_DOES_NOT_CONTAIN_ALL_COMPONENTS = 'ENCLOSURE_DOES_NOT_CONTAIN_ALL_COMPONENTS',
  BOM_COPIED_TO_CLIPBOARD = 'BOM_COPIED_TO_CLIPBOARD',
  PRODUCT_ADDED_TO_FAVORITE = 'PRODUCT_ADDED_TO_FAVORITE',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  PRODUCT_IS_NOT_AVAILABLE = 'PRODUCT_IS_NOT_AVAILABLE',
  LAST_SELECTED_APARTMENT = 'LAST_SELECTED_APARTMENT',
  FILE_UPLOAD = 'FILE_UPLOAD',
  CHECK_COMPANY_ADDRESS = 'CHECK_COMPANY_ADDRESS',
  APP_HAS_PROBLEMS = 'APP_HAS_PROBLEMS',
  ADDITIONAL_POWER_SUPPLY = 'ADDITIONAL_POWER_SUPPLY',
  LAST_SELECTED_ENCLOSURE = 'LAST_SELECTED_ENCLOSURE',
  AUXILIARY_AMPERE_INFO = 'AUXILIARY_AMPERE_INFO',
  INS_RATED_CURRENT_RESTRICTIONS_INFO = 'INS_RATED_CURRENT_RESTRICTIONS_INFO',
  NSXM_SHORT_CIRCUIT_RESTRICTIONS_INFO = 'NSXM_SHORT_CIRCUIT_RESTRICTIONS_INFO',
  NSX_SHORT_CIRCUIT_RESTRICTIONS_INFO = 'NSX_SHORT_CIRCUIT_RESTRICTIONS_INFO',
  DIN_RAIL_FOR_MOUNTING_WAS_ADDED_TO_BOM = 'DIN RAIL FOR MOUNTING WAS ADDED TO BOM',
  COVER_IS_PERFORATED_AND_CAN_BE_BROKEN_OUT_MANUALLY = 'COVER IS PERFORATED AND CAN BE BROKEN OUT MANUALLY',
  SPACER_INCLUDED_IN_DELIVERY_TOTAL_1_5_MODULES = 'SPACER INCLUDED IN DELIVERY TOTAL 1.5 MODULES',
  PLACEHOLDER_WILL_NOT_APPEAR_IN_BOM = 'PLACEHOLDER, WILL NOT APPEAR IN BOM',
  SPACER_SET_OF_5 = 'SPACER SET OF 5',
  FAILED_TO_DOWNLOAD_THE_DOCUMENT = 'FAILED TO DOWNLOAD THE DOCUMENT',
  LIMITED_AVAILABILITY_DEVICES_INFO = 'LIMITED AVAILABILITY DEVICES INFO',
  COMB_BUS_BAR_RECOMMENDATION_INFO = 'COMB BUS BAR RECOMMENDATION INFO',
  CURRENT_TRANSFORMERS_REQUIRED = 'CURRENT TRANSFORMERS REQUIRED'
}
