<div class="row">
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <p class="font-size-16 text-color-brand font-weight-semibold">
      <ng-container i18n="@@app.upload_logo_title">Upload company logo</ng-container>
    </p>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-inline-flex font-weight-semibold text-color-medium-gray">
    <!-- Hidden until Auth0 API sends valid logo for the selected company -->
    <!-- <div class="d-flex mr-2 cursor-pointer" (click)="showLogo(2)">
      <ng-container *ngIf="logoOptionSelected === 2 else uncheckedRadio">
        <img class="pr-2" src="./assets/images/radio_chacked_icon.svg">
      </ng-container>
      <span class="pr-1 py-1 text-uppercase" [ngClass]="{ 'color-black font-weight-bold': logoOptionSelected === 2}">
        <ng-container i18n="@@app.your_company_logo">Your Company</ng-container>
      </span>
    </div> -->
    <div class="d-flex mr-2 cursor-pointer">
        <img class="pr-2" src="./assets/images/radio_chacked_icon.svg">
      <span class="pr-1 py-1 text-uppercase color-black font-weight-bold">
        <ng-container i18n="@@app.custom_logo">Custom</ng-container>
      </span>
    </div>
  </div>
</div>
<ng-template #uncheckedRadio>
  <img class="pr-2" src="./assets/images/radio_empty_icon.svg">
</ng-template>
<div class="row mt-3">
  <div class="col-md-11 mx-3 text-center white-bg border-brand-thick position-relative d-flex justify-content-center align-items-center logo-container-height">
    <div class="text-center">
      <ng-container *ngIf="(logoOptionSelected === documentLogoType.DISPOSUITE_LOGO)">
        <div>
          <p class="m-0">
            <ng-container i18n="@@app.select_file">Select a file to upload</ng-container>
          </p>
          <div>
            <img src="/assets/images/DispoSuite_logo@3x.png" class="opacity-3 w-100">
          </div>
          <input class="input-content-hidden" type="file" #fileInput accept=".jpg,.jpeg,.png" (click)="fileInput.value = null" value="" (click)="fileInput" (change)="onImageSelect($event.target.files)">
          <button type="button" [ngClass]="{'disabled': isLoading}" class="btn bg-brand text-color-white btn-md w-100-px text-uppercase border-rounded"
                  (click)="fileInput.click()">
            <ng-container i18n="@@app.browse_logo">Browse</ng-container>
          </button>
          <div class="invalid-feedback display-inline-block">
            <div *ngIf="errorMessage === documentLogoErrorType.SIZE">
              <p class="m-0">
                <ng-container i18n="@@app.file_size_exceeded">File size limit exceeded</ng-container>
              </p>
            </div>
            <div *ngIf="errorMessage === documentLogoErrorType.TYPE">
              <p class="m-0">
                <ng-container i18n="@@app.invalid_file_type">Only JPEG or PNG format is allowed</ng-container>
              </p>
            </div>
            <div *ngIf="errorMessage === documentLogoErrorType.DIMENSIONS">
              <p class="m-0">
                <ng-container i18n="@@app.invalid_image_dimension">Either width or height greater than 640px</ng-container>
              </p>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="logoOptionSelected === documentLogoType.CUSTOM">
        <ng-container *ngIf="image; else loadingImage">
          <img [src]="image" class="logo-max-height mw-100">
          <button type="button" class="close color-black no-outline position-absolute top-0 right-0" (click)="clearImage()">&times;</button>
        </ng-container>
        <ng-template #loadingImage>
          <app-ui-loading minHeight="20"></app-ui-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div *ngIf="imageProportion | companyLogoProportion:logoOptionSelected">
  <div class="row bg-dark-gray py-1 px-2 mx-0 mt-3 arrow_box d-inline-flex text-color-mandatory align-items-center border-radius-10 w-100
    bg-ultra-light-grey-2">
    <i class="pb-1 px-1 col-md-1">
      <img src="/assets/images/info_icon.png" alt="info" width="15">
    </i>
    <div class="py-1 pl-1 font-size-10 font-weight-bolder col-md-11">
      <label>
        <ng-container i18n="@@app.proportion_recommendation">For best results consider adding a logo of the below mentioned dimensions</ng-container>
      </label>
    </div>
  </div>
</div>
<div
  class="row bg-dark-gray py-1 px-2 mx-0 mt-3 arrow_box d-inline-flex text-color-medium-gray align-items-center border-radius-10 w-100
  bg-ultra-light-grey-2">
  <i class="pb-1 px-1 col-md-1">
    <img src="/assets/images/info_icon.png" alt="info" width="15">
  </i>
  <div class="py-1 pl-1 font-size-10 font-weight-bolder col-md-11">
    <div>
      <label>
        <ng-container i18n="@@app.logo_restriction">Logo must be in PNG or JPG format</ng-container>
      </label>
    </div>
    <div>
      <label>
        <ng-container i18n="@@app.logo_size_recommendation">Recommendations: Logo size should be below 3Mb and not more than 640px in height or width. </ng-container>
      </label>
    </div>
  </div>
</div>
