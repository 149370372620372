import { Injectable, Injector, Inject } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastComponent } from './components/toast.component';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { NotificationService } from '../shared/services/notification.service';
import { ToastInfoEnum } from './toast.info.enum';
import { LanguageService } from '../shared/services/language.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private lastToast: ToastRef;
  private readonly toastPositionsY: number[];
  private readonly toastReferences: ToastRef[];

  constructor(
    private readonly overlay: Overlay,
    private readonly parentInjector: Injector,
    private readonly notificationService: NotificationService,
    private readonly languageService: LanguageService,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {
    this.toastPositionsY = [];
    this.toastReferences = [];
  }

  show(data: ToastData) {
    const positionStrategy = this.getPositionStrategyInitial();
    const yPos = this.getPosition();
    const overlayRef = this.overlay.create({ positionStrategy, panelClass: 'cdk-toaster-pane' });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector({ ...data, language: this.languageService.getCurrentLanguage() }, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    const ref = overlayRef.attach(toastPortal);
    this.toastPositionsY.push(yPos);
    this.toastReferences.push(toastRef);

    ref.instance.onClosing?.subscribe( _ => {
      this.toastPositionsY.shift();
      const removedToast = this.toastReferences.shift();

      this.updatePositionForOtherActiveToastMessages(removedToast);
    });

    return toastRef;
  }

  addToastToNotifications(data: ToastData, enclosureId: number = 0) {
    if (enclosureId === null) {
      return;
    }
    // If the modal is shown from the project-layer, then it has no enclosureId
    // Theres is no need to save the notifications in that case
    this.notificationService.addToNotifications(
      { messageInfo: (data.text as ToastInfoEnum), messageType: data.type, language: this.languageService.getCurrentLanguage() },
      enclosureId);
  }

  private updatePositionForOtherActiveToastMessages(removedToast: ToastRef) {
    this.toastReferences.forEach((e, i) => {
      this.toastPositionsY[i] = this.toastPositionsY[i] - removedToast?.getHeight()?.height;
      e?.setPosition(this.getPositionStrategyClosing(this.toastPositionsY[i]));
    });
  }

  private getPositionStrategyInitial() {
    return this.getPositionStrategy(this.getPosition());
  }

  private getPositionStrategyClosing(sub: number) {
    return this.getPositionStrategy(sub);
  }

  private getPositionStrategy(top: number) {
    return this.overlay.position()
      .global()
      .bottom(top + 'px')
      .right(window.innerWidth > 1919 ? 'calc(50% - 900px)' : 'calc(0% + 50px)');
  }

  private getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    return lastToastIsVisible
      ?  ( this.toastPositionsY[this.toastPositionsY.length - 1] + this.lastToast?.getHeight()?.height )
      : this.toastConfig.position.top;
  }

  private getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
