import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, map } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

import { UtilService } from './shared/services/util.service';
import { LanguageService } from './shared/services/language.service';
import { ConfigurationService } from './shared/services/configuration.service';
import { environment, userProfileUrl } from '../environments/environment';
import { preloadSVGImages } from './shared/helpers/preloadImage.helper';
import { UpdateAppService } from './shared/services/update-app.service';
import { ConfirmationModalComponent } from './ui/components/confirmation-modal/confirmation-modal.component';
import { GuestInformationComponent } from './ui/components/guest-information/guest-information.component';
import Package from './../../package.json';
import { APPLICATION_VERSION_SKIP } from './shared/constants/app.constant';
import { ErrorService } from './shared/services/error.service';
import { ToastInfoEnum } from './toast/toast.info.enum';
import { WhiService } from './shared/services/whi.service';
import { FeedbackService } from './shared/services/feedback.service';
import { FeedbackButtonComponent } from './ui/components/feedback-button/feedback-button.component';
import { UploadlogoComponent } from './ui/components/uploadlogo/uploadlogo.component';
import { UserService } from './shared/services/user.service';
import { User, UserUpdateCommand } from './shared/models/user';
import { InvitationService } from './home/services/invitation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('updateConfirmation') updateConfirmationEl: ConfirmationModalComponent;
  @ViewChild('guestInformationComponent') guestComponent: GuestInformationComponent;
  @ViewChild('feedbackButtonComponent') feedbackButtonComponentEl: FeedbackButtonComponent;
  @ViewChild('settingsComponent') settingsComponent: UploadlogoComponent;

  updateVersion: string;
  updateInfo: string[];
  imagePath: string;
  selectedLanguage: string;
  isLoggedIn$: Observable<boolean>;
  userProfileUrl: string;
  userInformation$: Observable<User>;

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly utilService: UtilService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly languageService: LanguageService,
    private readonly configService: ConfigurationService,
    private readonly updateAppService: UpdateAppService,
    private readonly whiService: WhiService,
    private readonly feedbackService: FeedbackService,
    private readonly invitationService: InvitationService
  ) {
  }

  ngOnInit() {
    this.utilService.appendGoogleAnalytics(environment.googleAnalyticsCode);
    this.utilService.appendGATrackingCode(environment.googleTrackingCode);
    this.utilService.appendGATrackingCodeInBody(environment.googleTrackingCode);

    this.updateAppService.applicationUpdateListener().pipe(
      map(() => {
        this.auth0UserInformationListener();
      })
    ).subscribe();

    this.profileEventListeners();

    preloadSVGImages();

    this.imagePath = this.configService.imagePath;
    this.isLoggedIn$ = this.authService.isAuthenticated$;
    this.userProfileUrl = userProfileUrl.domain;
    this.userInformation$ = this.authService.user$;
    this.languageService.configureLanguageSettings();
    this.selectedLanguage = this.languageService.getCurrentLanguage();
    this.redirectPageOnLanguageChange();
    this.shouldOpenGuestWindow();
    this.applicationErrorListener();
    this.storeWhiIntegrationHookUrl();
  }

  loginGuest() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`
      },
      // appState: { target: this.languageService.getLocalString() }
    });
  }

  login() {
    this.authService.loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}`
      },
      appState: { target: '/' }
    });
  }

  logout() {
    this.authService.logout({
      logoutParams: {
        returnTo: `${window.location.origin}`
      }
    });
  }

  private auth0UserInformationListener() {
    return this.userService.getUserInfo(UserUpdateCommand.CHECK_UPDATE).pipe(
      map((user: User) => {
        this.userService.setUserInformation(user);
        if (this.userService.shouldDisplayVersionUpdate(user)) {
          this.updateApplicationInfoBox();
        }

        return user;
      })
    );
  }

  showFeedbackButton() {
    this.feedbackService.show();
  }

  private shouldOpenGuestWindow() {
    this.authService.getAccessTokenSilently().pipe(
      map(_ => {
        this.guestComponent.closeModal();

        this.auth0UserInformationListener().subscribe((user: User) => {
          this.authTokenHandler(user?.lang);
          // this.feedbackService.init(user?.name, user?.email, user?.lang, this.feedbackButtonComponentEl);
        });
      }),
      catchError(_ => {

        this.userService.cleanLocalStorage();
        this.guestComponent?.openModal();
        this.storeTokenForSharedProject();

        return of();
      })).subscribe();
  }

  private storeTokenForSharedProject() {
    if(this.router.url.includes('shared')) {
      const { i } = this.activatedRoute.snapshot.queryParams;
      this.storeInvitation(i);
    }
  }

  private storeInvitation(token: string) {
    if (!token) {
      return;
    }

    sessionStorage.setItem('i', token);
  }

  private redirectPageOnLanguageChange() {
    const redirectToUrl = sessionStorage.getItem('redirectTo');
    sessionStorage.removeItem('redirectTo');

    if (redirectToUrl?.length > 0) {
      this.router.navigateByUrl(redirectToUrl.replace('#/', ''));
    }
  }

  private profileEventListeners(): void {
    window.addEventListener('message', (event) => {
      if (event?.origin !== userProfileUrl?.domain ) { return; }

      switch (event?.data?.type) {
        case 'FELLER_USER_UPDATE':
          this.authService.getAccessTokenSilently({ cacheMode: 'off' })
            .subscribe(_ => {
              this.userService.getUserInfo(UserUpdateCommand.CHECK_UPDATE).subscribe((user: User) => {
                this.userService.setUserInformation(user, false);
              });
            });
          break;
        case 'FELLER_USER_DELETE':
        case 'FELLER_USER_LOGOUT':
          this.logout();
          break;
      }
    }, false);
  }

  private defaultLanguage(language: string) {
    if (environment.languageSettings.availableLanguages.includes(language)) {
      return language;
    }

    return environment.languageSettings.defaultLanguage;
  }

  private applicationErrorListener() {
    this.errorService.getApplicationErrorState$().subscribe(status => {
      if (status === 401) {
        this.utilService.showToastWarning({ errors: [{ details: ToastInfoEnum.APP_HAS_PROBLEMS  }] });
      }
    })
  }

  private authTokenHandler(lang: string) {
    const language = this.defaultLanguage(lang);

    this.languageService.setCustomLanguage(language);
    this.selectedLanguage = language;

    const { i } = this.activatedRoute.snapshot.queryParams;
    this.storeInvitation(i);

    const redirecting = UtilService.handleAppLanguage(language);
    if (typeof redirecting === 'string') {
      UtilService.redirectTo(redirecting);
      return;
    }

    if (!redirecting) {
      const sharedInvitation = sessionStorage.getItem('i');
      if (sharedInvitation) {

        this.router.navigateByUrl('/home/shared')
          .then(_ => {
            this.invitationService.invitationReceived(sharedInvitation);
            sessionStorage.removeItem('i');
          });
      }
    }
  }

  private storeWhiIntegrationHookUrl() {
    this.activatedRoute.queryParams
      .pipe(
        filter(params => params.hasOwnProperty(this.whiService.hookUrl)),
        map(params => params[this.whiService.hookUrl])
      )
      .subscribe(hookUrl => {
        this.whiService.storeHookUrl(hookUrl);
      });
  }

  private updateApplicationInfoBox() {
    this.updateAppService.getUpdateInfo(this.languageService.getCurrentLanguage()).subscribe(({ version, info }) => {
      this.updateInfo = info;
      this.updateVersion = version;
      this.updateConfirmationEl.openModal();
    })
  }

  onChangeLanguage(language) {
    this.userService.updateUserLanguage(language)
      .subscribe(_ => {
        this.languageService.setCustomLanguage(language);
        if (!environment.production) {
          return;
        }
        sessionStorage.setItem('redirectTo', location.hash);
        location.assign(UtilService.redirectionURI(language));
      });
  }

  openSettings() {
    this.settingsComponent.openModal();
  }

  onUpdateVersionConfirmed() {
    /**
     * As we introduced version info for 1.3.1, upon clicking on Ok, if app version is 1.3.1 nothing will happen
     * version variable comes from package.json
     */
    if (Package.version !== APPLICATION_VERSION_SKIP) {
      location.reload();
    }
  }
}
